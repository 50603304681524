import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import FieldBlock from "components/FieldBlock";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useFetchApi from "hooks/useFetchApi";

export default function FieldBlockDate({
  label,
  name,
  value,
  id,
  route,
  sectionID,
  editAll,
  updateGroupAnswers,
  fetchData,
  readonly,
}) {
  const [fetchApi] = useFetchApi();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  // Parse the initial value string to a Date object
  const parsedValue = useMemo(
    () =>
      value
        ? new Date(
            parseInt(value.substring(0, 4), 10),
            parseInt(value.substring(5, 7), 10) - 1, // Months are 0-indexed
            parseInt(value.substring(8, 10), 10),
          )
        : new Date(),
    [value],
  );

  const [answer, setAnswer] = useState(parsedValue);

  const onSubmit = async () => {
    setLoading(true);
    document.activeElement?.blur();
    const adjustedAnswer = new Date(answer.setHours(answer.getHours() + 8));
    const body = route
      ? { [route]: { [name]: adjustedAnswer, id: sectionID } }
      : { [name]: adjustedAnswer };
    try {
      await fetchApi(`update-dossier/${id}`, { method: "PUT", body });
      fetchData();
    } catch ({ message }) {
      setAnswer(parsedValue);
    }
    setLoading(false);
    setEditing(false);
  };
  const onCancel = () => {
    setEditing(!editing);
    setAnswer(parsedValue);
  };

  const handleAnswer = (item) => {
    setAnswer(item);
    updateGroupAnswers(name, item, route, sectionID);
  };

  useEffect(() => {
    setAnswer(parsedValue);
  }, [parsedValue]);

  useEffect(() => {
    if (!editAll) {
      setEditing(false);
    }
  }, [editAll]);

  return (
    <FieldBlock
      label={label}
      displayValue={value ? value.substring(0, 10) : "Sélectionner une date"}
      loading={loading}
      editing={editing}
      editAll={editAll}
      readonly={readonly}
      onSubmit={onSubmit}
      onCancel={onCancel}
      setEditing={setEditing}
    >
      <DatePicker
        dateFormat="yyyy-MM-dd"
        value={answer}
        selected={new Date(answer)}
        onChange={editAll ? handleAnswer : setAnswer}
        autoFocus={!editAll}
        disabled={loading}
        className="w-[570px] border-2 border-sky-600 rounded outline-none p-1"
      />
    </FieldBlock>
  );
}
FieldBlockDate.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  id: PropTypes.number,
  route: PropTypes.string,
  sectionID: PropTypes.number,
  readonly: PropTypes.bool,
  editAll: PropTypes.bool,
  updateGroupAnswers: PropTypes.func,
  fetchData: PropTypes.func,
};
