import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link, Navigate } from "react-router-dom";
import { translateMessage } from "functions";
import AuthLayout from "components/Auth/AuthLayout";
import Form, { FormContext } from "components/Form";
import Button from "components/Button";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";

function FormChildren({
  children,
  title,
  submitText,
  cancelUrl,
  cancelText,
  onSuccess,
  navigateToOnSuccess,
}) {
  const { error, success, loading } = useContext(FormContext);

  if (success) {
    if (onSuccess) {
      onSuccess();
    }
    if (navigateToOnSuccess) {
      return <Navigate to={navigateToOnSuccess} />;
    }
  }

  return (
    <>
      <h1 className="text-main-color font-semibold text-2xl">{title}</h1>
      {children}
      {error && (
        <p className="text-red-500 text-sm text-center">
          {translateMessage(error)}
        </p>
      )}
      <div className="text-center">
        <Button
          type="submit"
          variant="contained"
          color="blue"
          disabled={loading}
          label={submitText ?? "Valider"}
          icon={loading ? <LoadingIcon /> : ""}
          className="py-3 mx-auto px-8 bg-main-color text-white rounded-lg font-bold"
        />
        {cancelUrl && (
          <div className="mt-3">
            <Link
              to={cancelUrl}
              className="text-xs underline underline-offset-2 hover:no-underline text-light-color"
            >
              {cancelText}
            </Link>
          </div>
        )}
      </div>
    </>
  );
}

const commonPropTypes = {
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  submitText: PropTypes.string,
  cancelUrl: PropTypes.string,
  cancelText: PropTypes.string,
  onSuccess: PropTypes.func,
  navigateToOnSuccess: PropTypes.string,
  initialError: PropTypes.string,
};

const commonDefaultProps = {
  children: null,
  submitText: null,
  cancelUrl: null,
  cancelText: null,
  onSuccess: null,
  navigateToOnSuccess: null,
};

FormChildren.propTypes = {
  ...commonPropTypes,
};

FormChildren.defaultProps = {
  ...commonDefaultProps,
};

export default function AuthForm({ submit, initialError, ...childrenProps }) {
  return (
    <AuthLayout>
      <Form
        initialError={initialError}
        className="flex flex-col bg-white z-10 gap-8 py-8 w-full max-w-[464px] m-auto"
        submit={submit}
      >
        <FormChildren {...childrenProps} />
      </Form>
    </AuthLayout>
  );
}

AuthForm.propTypes = {
  ...commonPropTypes,
  initialError: PropTypes.string,
  submit: PropTypes.func.isRequired,
};

AuthForm.defaultProps = {
  ...commonDefaultProps,
};
