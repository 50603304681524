import React, { useState, useEffect } from "react";
import { useAuth } from "components/AuthProvider";
import { Link, useSearchParams } from "react-router-dom";
import Input from "components/Input";
import AuthForm from "components/Auth/AuthForm";
import { useConfig } from "providers/ConfigProvider";

export default function Login() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultValues] = useState({
    email: searchParams.get("email"),
    simulationId: searchParams.get("simulationId"),
    simulationCode: searchParams.get("simulationCode"),
    token: searchParams.get("token"),
    error: searchParams.get("error"),
  });

  const { isCustomerUrl } = useConfig();
  const auth = useAuth();

  useEffect(() => {
    setSearchParams({});
  }, [setSearchParams]);

  // Auto login avec le token
  useEffect(() => {
    if (defaultValues.token) {
      auth.login(null, null, defaultValues);
    }
  }, [defaultValues, auth]);

  const submit = async ({ identifier, password }) => {
    await auth.login(identifier, password, defaultValues);
  };

  const cancelProps = isCustomerUrl
    ? {
        cancelUrl: "/create-account",
        cancelText: "Je n'ai pas encore de compte, je crée un compte.",
      }
    : {};

  return (
    <AuthForm
      initialError={
        defaultValues.error === "expired"
          ? "Votre session a expiré, veuillez vous reconnecter."
          : undefined
      }
      title="Connexion"
      submit={submit}
      submitText="Se connecter"
      {...cancelProps}
    >
      <Input
        name="identifier"
        label="E-mail"
        type="email"
        defaultValue={defaultValues.email}
        autoComplete="email"
        required
      />
      <Input
        name="password"
        label="Mot de passe"
        type="password"
        autoComplete="on"
        required
      >
        <Link
          className="text-light-color underline text-xs"
          to="/forgot-password"
        >
          Mot de passe oublié
        </Link>
      </Input>
    </AuthForm>
  );
}
