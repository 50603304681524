import React, { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";
import qs from "qs";
import PropTypes from "prop-types";
import Column from "components/DataTable/Column";
import useSWR from "swr";
import useFetchApi from "hooks/useFetchApi";
import { ReactComponent as Chevron } from "images/icons/chevron.svg";

import { translateMessage } from "functions";

function getFieldValueUrl(entity = null, field = null, filters = {}) {
  const fieldQueryString = qs.stringify(
    { filters },
    { encodeValuesOnly: true },
  );
  return `${entity}/field/${field}?${fieldQueryString}`;
}

export default function FilterableColumn({
  children,
  className = "",
  entity,
  filters = {},
  column,
  filterValues = null,
  setFilterValues,
}) {
  const [open, setOpen] = useState(false);
  const hasFixedOptions = !!column.options;

  // Repositionne la popup en mode portal par dessus la colonne
  const columnRef = useRef();
  const popup = useRef();
  useEffect(() => {
    if (open && popup.current && columnRef.current) {
      const { x, y } = columnRef.current.getBoundingClientRect();
      popup.current.style.left = `${x}px`;
      popup.current.style.top = `${y}px`;
      popup.current.style.zIndex = 10000;
    }
  }, [open]);

  // Fetch les options en back à la première ouverture de la popup
  // à moins que la colonne ait déjà des valeurs en dur dans options.
  const [shouldFetchData, setShouldFetchData] = useState(false);
  useEffect(() => {
    if (!hasFixedOptions && open && !shouldFetchData) {
      setShouldFetchData(true);
    }
  }, [open, shouldFetchData, hasFixedOptions]);
  const [fetchApi] = useFetchApi();

  const fieldValueUrl =
    !hasFixedOptions && getFieldValueUrl(entity, column.filterField, filters);

  const {
    data: fetchedOptions,
    error,
    isLoading,
  } = useSWR(shouldFetchData && fieldValueUrl, async (url) => {
    if (!url) {
      return null;
    }
    const res = await fetchApi(url);
    return res;
  });

  const options =
    (hasFixedOptions ? column.options : fetchedOptions?.data) || [];

  options.sort(column?.sortOptions);

  return (
    <Column ref={columnRef} className={`h-0 ${className}`}>
      {
        /* This is a click trap to close the filter when clicking outside of it. */
        open && (
          <div
            className="fixed top-0 left-0 right-0 bottom-0 z-40"
            onClick={() => setOpen(false)}
          ></div>
        )
      }
      <div
        className="h-full flex gap-2 cursor-pointer items-center z-50"
        onClick={() => setOpen((prev) => !prev)}
      >
        {children}
        {filterValues?.size && filterValues.size > 0
          ? ` (${filterValues.size})`
          : ""}
        <Chevron className={`w-2 ${!open ? "rotate-180" : ""}`} />
      </div>
      {open &&
        createPortal(
          <div ref={popup} className="absolute pt-12">
            <div className="w-[400px] max-h-[430px] bg-white border-0.5 rounded overflow-auto z-50">
              {isLoading && (
                <div className="w-full flex-1 flex justify-center items-center p-3">
                  <span className="w-8 h-8 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
                </div>
              )}
              {error && (
                <div className="text-red-500 flex items-center gap-2 px-4 py-2">
                  <b>Erreur&nbsp;:</b>
                  {translateMessage(error.message)}
                </div>
              )}
              {!isLoading && !error && (
                <>
                  <label className="flex items-center gap-2 px-4 py-2 cursor-pointer">
                    <input
                      type="checkbox"
                      className="accent-blue"
                      checked={options?.length === filterValues?.size}
                      onChange={(event) =>
                        setFilterValues(
                          event.target.checked ? new Set(options) : null,
                        )
                      }
                    />
                    <span className="text-xs font-semibold text-main-color">
                      {column.selectOptionLabel ||
                        `Sélectionner une ou plusieurs options`}
                    </span>
                  </label>
                  <div className="flex flex-col gap-1 px-2 pb-2">
                    {options.map((option) => {
                      const selected = filterValues?.has(option);
                      const selectedClass = selected
                        ? "bg-light-color text-main-color border-blue font-semibold"
                        : "";
                      return (
                        <label
                          key={option}
                          title={option ?? "Valeur non définie"}
                          className={`cursor-pointer flex items-center gap-2 text-xs rounded-sm border-0.5 px-2 py-1.5 text-black ${selectedClass}`}
                        >
                          <input
                            type="checkbox"
                            className="accent-blue border-blue"
                            checked={selected ?? false}
                            onChange={(event) => {
                              const newFilterValues = new Set([
                                ...(filterValues ?? []),
                              ]);
                              if (event.target.checked) {
                                newFilterValues.add(option);
                              } else {
                                newFilterValues.delete(option);
                              }

                              setFilterValues(newFilterValues);
                            }}
                          />
                          {column.formatOption
                            ? column.formatOption(option)
                            : option ?? "-"}
                        </label>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>,
          document.body,
        )}
    </Column>
  );
}
FilterableColumn.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  entity: PropTypes.string.isRequired,
  filters: PropTypes.instanceOf(Object),
  column: PropTypes.instanceOf(Object).isRequired,
  filterValues: PropTypes.instanceOf(Set),
  setFilterValues: PropTypes.func.isRequired,
};
