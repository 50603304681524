import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import PropTypes from "prop-types";

export const RefreshContext = createContext();

/**
 * Hook du composant enfant qui exécute la fonction lorsque le parent a été refresh
 * @param {function} onRefresh Fonction à appeler quand un refresh a été initié
 */
export function useEffectRefresh(onRefresh) {
  const refreshValue = useContext(RefreshContext);
  const [lastRefreshValue, setLastRefreshValue] = useState(refreshValue);
  useEffect(() => {
    if (refreshValue !== lastRefreshValue) {
      setLastRefreshValue(refreshValue);
      onRefresh();
    }
  }, [refreshValue, lastRefreshValue, onRefresh]);
}

/**
 * Hook du composant parent qui retourne la valeur à fournir au composant <RefreshProvider> ainsi que la fonction refresh()
 * @returns {Array<number, function>}
 */
export function useRefresh() {
  const [refreshValue, setRefreshValue] = useState(0);
  const refresh = useCallback(() => {
    setRefreshValue(new Date().getTime());
  }, [setRefreshValue]);
  return [refreshValue, refresh];
}

/**
 * Composant RefreshProvider
 * @param {object} param0
 * @param {object} param0.children Nœuds enfants
 * @param {object} param0.value Valeur de refresh retournée par useRefresh()
 * @returns
 */
export default function RefreshProvider({ children, value }) {
  return (
    <RefreshContext.Provider value={value}>{children}</RefreshContext.Provider>
  );
}
RefreshProvider.propTypes = {
  value: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
};
