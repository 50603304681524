import React, { useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "components/AuthProvider";
import useFetchApi from "hooks/useFetchApi";
import Main from "components/Main";
import Status from "components/Status";
import btnBack from "images/icons/back.svg";
import Beneficiaire from "components/MyFileTabs/Beneficiaire";
import Projet from "components/MyFileTabs/Projet";
import Chantier from "components/MyFileTabs/Chantier";
import Documents from "components/MyFileTabs/Documents";
import Messagerie from "components/MyFileTabs/Messagerie";
import UnimplementedPage from "components/UnimplementedPage";

import useSWR from "swr";
import Loader from "components/Loader";

const tabTitles = {
  beneficiaire: "Bénéficiaire",
  projet: "Projet",
  chantier: "Chantier",
  documents: "Documents",
  messagerie: "Messagerie",
  "messagerie-beneficiaire": "Messagerie bénéficiaire",
  "messagerie-partenaire": "Messagerie partenaire",
};

export default function MyFilesDetails() {
  const [fetchApi] = useFetchApi();
  const { fileID, tab } = useParams();
  const { isManager, isPartner, role } = useAuth();
  const isBackOfficeUser = isManager || isPartner;
  const navigate = useNavigate();

  const isConsultation = role === "Consultation";
  const defaultTab = isManager ? "beneficiaire" : "projet";
  const currentTab = tab ?? defaultTab;
  const setCurrentTab = (tab) => {
    navigate(
      `/${isBackOfficeUser ? "dossiers" : "mes-projets"}/${fileID}${tab !== defaultTab ? "/" + tab : ""}`,
    );
  };

  const {
    data,
    error,
    isLoading,
    mutate: refreshData,
  } = useSWR(`dossiers/${fileID}?populate=deep,3`, fetchApi);

  const handleOnClickBack = useCallback(
    () => navigate("/dossiers", { replace: true }),
    [navigate],
  );

  const partenaireUsers =
    data?.data?.attributes?.partenaire?.data?.attributes?.users?.data ?? [];
  const hasPartenaires = partenaireUsers.length > 0;

  function SwitchTabs(title, statut) {
    switch (title) {
      case "beneficiaire":
        return (
          <Beneficiaire id={+fileID} data={data} fetchData={refreshData} />
        );

      case "projet":
        return <Projet id={+fileID} data={data} fetchData={refreshData} />;

      case "chantier":
        return <Chantier id={+fileID} data={data} fetchData={refreshData} />;

      case "documents":
        return <Documents id={+fileID} data={data} statut={statut} />;

      case "messagerie":
      case "messagerie-beneficiaire":
      case "messagerie-partenaire":
        return (
          <Messagerie
            id={+fileID}
            statut={statut}
            fetchData={refreshData}
            channel={
              (isManager && title === "messagerie-beneficiaire" && "client") ||
              (hasPartenaires &&
                isManager &&
                title === "messagerie-partenaire" &&
                "partner") ||
              (hasPartenaires && isPartner && "partner") ||
              "client"
            }
          />
        );

      default:
        return <UnimplementedPage title={currentTab} />;
    }
  }

  if (!data) {
    return (
      <div className="w-full flex-1 flex justify-center items-center">
        <Loader isLoading={isLoading} error={error} />
      </div>
    );
  } else {
    const fileData = {
      statut: data?.data?.attributes?.statut,
      reference: data?.data?.attributes?.reference,
      title: data?.data?.attributes?.operation,
    };
    const tabs = (isManager &&
      !isConsultation && [
        "beneficiaire",
        "chantier",
        "documents",
        ...(hasPartenaires
          ? ["messagerie-beneficiaire", "messagerie-partenaire"]
          : ["messagerie"]),
      ]) ||
      (isConsultation && ["beneficiaire", "chantier", "documents"]) || [
        "projet",
        "documents",
        "messagerie",
      ];

    return (
      <Main
        title={
          <>
            <button className="pr-3" onClick={handleOnClickBack}>
              <img src={btnBack} alt="retour" />
            </button>
            {isBackOfficeUser ? (
              <>
                Vue détaillée dossier REF {fileID} :
                <span className="font-normal"> {fileData.reference}</span>
              </>
            ) : (
              <>
                Vue détaillée du projet :
                <span className="font-normal"> {fileData.title}</span>
              </>
            )}
          </>
        }
      >
        <Status
          status={fileData.statut}
          id={+fileID}
          data={data}
          fetchData={refreshData}
        />

        <div className="-mx-4 mt-2 border-b-2 pb-1">
          {tabs.map((item) => (
            <button
              key={item}
              className={
                currentTab === item
                  ? "inline mx-4 text-main-color font-medium underline underline-offset-10"
                  : "inline mx-4 font-medium text-[#9E9E9E]"
              }
              onClick={() => {
                if (item !== currentTab) {
                  // Actualisation de l'onglet messagerie ou documents au cas où de nouveaux éléments auraient été ajoutés.
                  if (item === "messagerie" || item === "documents") {
                    refreshData();
                  }
                  setCurrentTab(item);
                }
              }}
            >
              {tabTitles[item]}
            </button>
          ))}
        </div>

        {SwitchTabs(currentTab, fileData.statut)}
      </Main>
    );
  }
}
