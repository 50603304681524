import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import { ReactComponent as EditIcon } from "images/icons/edit.svg";
import { ReactComponent as CancelIcon } from "images/icons/cancel.svg";
import { ReactComponent as ConfirmIcon } from "images/icons/confirm.svg";

export default function FieldBlock({
  label,
  displayValue,
  children,
  loading,
  editing,
  editAll,
  readonly,
  onSubmit,
  onCancel,
  setEditing,
}) {
  return (
    <div
      className={`flex items-center justify-between border-b-2 pb-1 mt-1 pt-1`}
    >
      <div className="flex flex-col">
        {label && <label className="text-xs">{label}</label>}
        {((!editing && !editAll) || readonly) && (
          <div className={readonly ? "" : "w-[650px]"}>
            {displayValue || <>&ndash;</>}
          </div>
        )}
        {(editing || editAll) && !readonly && children}
      </div>
      {!editAll && !readonly && (
        <div>
          {!loading && editing && (
            <div>
              <button onClick={onSubmit} className="align-middle">
                <ConfirmIcon alt="confirmer" className="max-w-none" />
              </button>
              <button onClick={onCancel} className="align-middle">
                <CancelIcon alt="annuler" className="max-w-none" />
              </button>
            </div>
          )}
          {!loading && !editing && (
            <button onClick={() => setEditing(true)} className="align-middle">
              <EditIcon alt="modifer" className="max-w-none" />
            </button>
          )}
          {loading && (
            <LoadingIcon className="animate-spin max-w-none w-[24px] h-[24px]" />
          )}
        </div>
      )}
    </div>
  );
}

FieldBlock.propTypes = {
  label: PropTypes.any,
  displayValue: PropTypes.any,
  children: PropTypes.any,
  loading: PropTypes.bool,
  editing: PropTypes.bool,
  editAll: PropTypes.bool,
  readonly: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  setEditing: PropTypes.func,
};
