import { React } from "react";
import PropTypes from "prop-types";

export default function Dialog({ children, onClose, loading }) {
  return (
    <div className="z-50 fixed top-0 left-0 right-0 bottom-0 bg-white bg-opacity-90 flex items-center justify-center">
      <div className="w-[80%] max-w-[537px] p-8 bg-white rounded border relative gap-3">
        <div>
          <button
            type="button"
            onClick={onClose}
            className="text-xs underline absolute right-4 top-4 z-20"
          >
            Fermer
          </button>

          {children}

          {loading && (
            <div className="bg-white rounded flex items-center justify-center p-8">
              <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
Dialog.propTypes = {
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
