const preMessages = {
  "Dossier incomplet avant-travaux":
    "Bonjour Madame, Monsieur, \n\nNous avons bien reçu votre demande de prime économies d’énergie et nous sommes heureux de pouvoir vous accompagner dans votre démarche d’amélioration de la performance énergétique de votre logement. \n\nToutefois, au regard des informations indiquées sur votre dossier, nous ne pouvons évaluer le montant de votre prime.\n\nEn effet, le devis (et la facture) doivent mentionner :\n\n-\n\nNous vous invitons à prendre contact avec votre artisan et à nous faire parvenir le devis actualisé via votre Espace bénéficiaire (ajout manuscrit non recevable).\n\nNous vous informons que sans réponse de votre part dans un délai de deux mois, nous considérerons que vous ne donnez pas suite à votre demande. Aucun document ne sera conservé au-delà de cette période.\n\nNous vous rappelons que vous ne devez pas signer vos devis avant d’avoir reçu notre lettre d’engagement ; en cas de non respect votre demande sera refusée.\n\nAttention : l’entreprise qui réalise les travaux doit être certifiée « reconnu Garant de l’environnement » (RGE) le jour de la signature du devis.\n\nRestant à votre disposition pour tout renseignement complémentaire, veuillez agréer, Madame, Monsieur, nos salutations distinguées.\n\nBien cordialement\n",
  "Dossier incomplet après-travaux":
    "Bonjour Madame, Monsieur,\n\nNous avons bien reçu l’attestation sur l’honneur, le devis signé, la facture et le certificat RGE en réponse à notre lettre d’engagement et nous vous en remercions.\n\nAfin de pouvoir examiner votre dossier, nous vous invitons à nous transmettre :\n\n-\n\nRestant à votre disposition pour tout renseignement complémentaire, veuillez agréer, Madame, Monsieur, nos salutations distinguées.\n\nBien cordialement\n",
  "Documents erronés après-travaux":
    "Bonjour Madame, Monsieur,\n\nNous avons bien reçu le devis daté-signé, la facture, l’attestation sur l’honneur et le certificat RGE en réponse à notre lettre d’engagement et nous vous remercions.\n\nToutefois, suite à l’examen de votre dossier, nous vous informons que nous ne pouvons donner une suite favorable à celui-ci pour les raisons suivantes :\n\n-\n\nNous vous remercions de XXXXX afin qu’on examine de nouveau votre dossier.\n\nRestant à votre disposition pour tout renseignement complémentaire, veuillez agréer, Madame, Monsieur, nos salutations distinguées.\n\nBien cordialement\n",
  "Litige après-travaux : manque critère facture":
    "Bonjour Madame, Monsieur,\n\nNous avons bien reçu l’attestation sur l’honneur, le devis signé, la facture et le certificat RGE en réponse à notre lettre d’engagement et nous vous en remercions.\n\nToutefois, suite à l’examen de votre dossier, nous vous informons que nous ne pouvons donner une suite favorable à celui-ci pour les raisons suivantes :\n\n- La facture ne mentionne pas\n\nRestant à votre disposition pour tout renseignement complémentaire, veuillez agréer, Madame, Monsieur, nos salutations distinguées.\n\nBien cordialement\n",
  "Litige après-travaux : devis signé avant RAI":
    "Bonjour Madame, Monsieur,\n\nNous vous informons que nous avons bien reçu le devis signé, la facture, l’attestation sur l’honneur et le certificat RGE en réponse à notre lettre d’engagement et nous vous en remercions.\n\nToutefois, suite à l’examen de votre dossier, nous vous informons que nous ne pouvons donner une suite favorable à celui-ci pour les raisons suivantes :\n\n- La date d’acceptation du devis (xx/xx/xxxx) est antérieure à la date de réception de la lettre d’engagement de SOREGIES (recommandé distribué le xx/xx/xxxx OU mail horodaté le xx/xx/xxxx).\n\nLes conditions générales de notre offre « prime économies d’énergie » précisent que le bénéficiaire doit transmettre sa demande avant la signature du devis et de surcroit avant d’avoir reçu la facture de l’artisan ou la lettre d’engagement de SOREGIES (article 4. conditions d’obtention).\n\nConformément aux règles qui nous sont imposées par le Ministère de la Transition Ecologique dans le cadre du dispositif des certificats d’économies d’énergie, nous ne pouvons présenter des dossiers pour lesquels SOREGIES n’est pas en mesure de prouver son rôle actif et incitatif et antérieur à l’engagement des opérations d’économies d’énergie.\n\nRestant à votre disposition pour tout renseignement complémentaire, veuillez agréer, Madame, Monsieur, nos salutations distinguées.\n\nBien cordialement\n",
  "Dossier non recevable autre prime CEE":
    "Bonjour Madame, Monsieur,\n\nVous nous avez transmis le devis des travaux de rénovation de votre habitation en vue de l’obtention de la prime économie d’énergie et nous vous en remercions.\n\nToutefois, suite à l’examen de votre dossier, nous vous informons que celui-ci n’est pas recevable pour les raisons suivantes :\n\n- Il est indiqué sur votre devis que les travaux ou prestations donneront lieu à une prime de XXXX dont le montant est déduit du total. Il n’est pas possible de cumuler une prime découlant du dispositif des Certificats d’Economies d’Energies pour les mêmes travaux auprès de différents organismes.\n\nConformément aux règles imposées par le Ministère de la Transition Ecologique nous ne pouvons présenter des dossiers pour lesquels SOREGIES n’est pas en mesure de prouver son rôle actif et incitatif et antérieur à l’engagement des opérations d’économies d’énergie.\n\nRestant à votre disposition pour tout renseignement complémentaire, veuillez agréer, Madame, Monsieur, nos salutations distinguées.\n\nBien cordialement\n",
  "Rappel d’envoi de facture":
    "Bonjour Madame, Monsieur,\n\nNous vous rappelons que pour pouvoir bénéficier de votre prime, il vous suffit de nous retourner dans un délai de trois mois après la réalisation de vos travaux :\n\n- L’attestation sur l’honneur complétée et signée avec votre artisan (document joint à la lettre d'engagement envoyée par mail horodaté / courrier recommandé,\n- La copie du devis de vos travaux signé avec la date d'acceptation du devis,\n- La copie de la facture définitive de vos travaux,\n- La copie de la qualification RGE de l’artisan ayant réalisé les travaux, valide à la signature du devis.\n- Le RIB au format bancaire, au nom du bénéficiaire de la prime.\n\nRestant à votre entière disposition pour tout renseignement complémentaire, veuillez agréer, Madame, Monsieur, nos sincères salutations.\n\nBien cordialement\n",
  "Envoi de documents originaux":
    "Bonjour Madame, Monsieur,\n\nNous avons bien reçu le devis daté-signé, la facture, l'attestation sur l'honneur et le certificat RGE par le biais de votre espace bénéficiaire et nous vous en remercions.\n\nAprès examen de votre dossier, celui-ci est conforme.Afin de le valider, nous vous invitons à nous retourner l'original de l'attestation sur l'honneur par voie postale à l'adresse ci-dessous :\n\nSORÉGIES\nPrime CEE\n78 avenue Jacques Coeur\nCS 10000\n86068 Poitiers Cedex 9\n\nRestant à votre entière disposition pour tout renseignement complémentaire, veuillez agréer, Madame, Monsieur, nos sincères salutations.\n\nBien cordialement\n",
  Autre:
    "Bonjour Madame, Monsieur,\n\nNous avons bien reçu votre demande de prime économies d’énergie et nous sommes heureux de pouvoir vous accompagner dans votre démarche d’amélioration de la performance énergétique de votre logement.\n\nNous vous informons que sans réponse de votre part dans un délai de deux mois, nous considérerons que vous ne donnez pas suite à votre demande. Aucun document ne sera conservé au-delà de cette période.\n\nRestant à votre disposition pour tout renseignement complémentaire, veuillez agréer, Monsieur, nos salutations distinguées.\n\nBien cordialement,\n",
};

export default preMessages;
