import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "components/AuthProvider";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";
import AuthForm from "components/Auth/AuthForm";
import AuthMessage from "components/Auth/AuthMessage";
import Input from "components/Input";
import { ReactComponent as SuccessIcon } from "images/icons/success.svg";

export default function ResetPassword() {
  const [fetchApi] = useFetchApi();
  const [success, setSuccess] = useState(false);
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");

  const submit = async (data) => {
    await fetchApi(`auth/reset-password`, { body: { ...data, code } });
    // Trigger a revalidation (refetch).
    mutate("auth/local");
    setSuccess(true);
  };

  if (success) {
    return (
      <AuthMessage
        title={
          <>
            <SuccessIcon className="mr-2 inline-block align-middle h-5" />
            Mot de passe modifié
          </>
        }
        message="Vous pouvez à présent vous connecter avec votre nouveau mot de passe."
      />
    );
  }

  return (
    <AuthForm
      submit={submit}
      title="Changement de mot de passe"
      submitText="Valider le mot de passe"
      cancelUrl="/"
      cancelText="Retour à la page de connexion"
      onSuccess={() => auth.logout()}
      navigateToOnSuccess="/"
    >
      <Input
        label="Nouveau mot de passe"
        name="password"
        type="password"
        autocomplete="new-password"
        placeholder="8 caractères ou plus, dont 1 majuscule et 1 chiffre."
        registerOptions={{
          minLength: {
            value: 8,
            message: "Le mot de passe doit contenir au minimum 8 caractères.",
          },
          validate: (value) =>
            (!!value?.match(/[a-z]/) &&
              !!value?.match(/[A-Z]/) &&
              !!value?.match(/[0-9]/)) ||
            "Le mot de passe doit comporter au moins 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre.",
        }}
        required
      />

      <Input
        label="Confirmer le nouveau mot de passe"
        name="passwordConfirmation"
        type="password"
        autocomplete="new-password"
        registerOptions={{
          validate: (value, formValues) =>
            value === formValues.password ||
            "Le mot de passe n'est pas identique.",
        }}
        required
      />
    </AuthForm>
  );
}
