import React from "react";
import PropTypes from "prop-types";

const Column = React.forwardRef(function (
  { children, className, ...props },
  ref,
) {
  return (
    <th
      ref={ref}
      className={`text-left h-0 font-normal bg-white text-stone-500 text-xs py-3 ${className}`}
      {...props}
    >
      {children}
    </th>
  );
});
Column.displayName = "Column";
Column.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};
Column.defaultProps = {
  className: "",
};
export default Column;
