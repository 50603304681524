import React from "react";
import PropTypes from "prop-types";
import Column from "components/DataTable/Column";
import { ReactComponent as ArrowIcon } from "images/icons/arrow.svg";

export default function SortableColumn({
  children,
  className = "",
  column,
  direction: directionParam = null,
  setDirection,
}) {
  const direction =
    (directionParam === "default" && column.defaultDirection) || directionParam;
  const rotate = direction === "asc" ? "" : "rotate-180";
  const color = direction
    ? "text-stone-500"
    : "text-stone-200 group-hover:text-stone-300";
  return (
    <Column
      className={`group ${className} cursor-pointer`}
      onClick={() => {
        if (!direction) {
          setDirection(column.defaultDirection);
        } else if (direction === "asc") {
          setDirection("desc");
        } else if (direction === "desc") {
          setDirection("asc");
        } else {
          setDirection(null);
        }
      }}
    >
      <div className="h-full flex items-center gap-2">
        {children} <ArrowIcon className={`${rotate} ${color} w-[9px]`} />
      </div>
    </Column>
  );
}
SortableColumn.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  column: PropTypes.instanceOf(Object).isRequired,
  direction: PropTypes.string,
  setDirection: PropTypes.func.isRequired,
};
