import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AuthLayout from "components/Auth/AuthLayout";
import Button from "components/Button";

export default function AuthMessage({
  title,
  message,
  error,
  className,
  button,
}) {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <div className="flex flex-col bg-white z-10 gap-8 py-8 w-full max-w-[500px] m-auto">
        {title && (
          <h1 className={`text-main-color font-semibold text-2xl ${className}`}>
            {title}
          </h1>
        )}
        {message && <p className={`text-md ${className}`}>{message}</p>}
        {error && (
          <p className={`text-red-500 text-md ${className}`}>{error}</p>
        )}
        {button && (
          <Button
            type="button"
            variant="contained"
            color="blue"
            label="Retour à la connexion"
            onClick={() => navigate("/")}
            className="py-3 mx-auto px-8 bg-main-color text-white rounded-lg font-bold"
          />
        )}
      </div>
    </AuthLayout>
  );
}

AuthMessage.propTypes = {
  title: PropTypes.any,
  message: PropTypes.any,
  error: PropTypes.any,
  button: PropTypes.bool,
  className: PropTypes.string,
};

AuthMessage.defaultProps = {
  title: null,
  message: null,
  error: null,
  button: true,
  className: "text-justify",
};
