// TODO: Supprimer et remplacer par ConfigProvider

import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { ConfigContext } from "providers/ConfigProvider";

// Create a context
const ApiEndpointContext = createContext();

// Create a custom hook to use the context
export function useApiEndpoint() {
  return useContext(ApiEndpointContext);
}

// Create a context provider component
export function ApiEndpointProvider({ children }) {
  const { apiUrl } = useContext(ConfigContext);
  const apiEndpoint = apiUrl;

  return (
    <ApiEndpointContext.Provider value={apiEndpoint}>
      {children}
    </ApiEndpointContext.Provider>
  );
}

ApiEndpointProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
