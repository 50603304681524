// TODO: Renommer en TitleProvider

import React, { createContext, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useConfig } from "providers/ConfigProvider";

const DomainContext = createContext();
export default DomainContext;

export function useDomain() {
  return useContext(DomainContext);
}

/**
 * Fournit un contexte client ou gestionnaire en fonction du domaine de l'URL.
 * Définit également le titre par défaut de la page.
 */
export function DomainProvider({ children }) {
  const {
    isManagerUrl: isManager,
    isCustomerUrl: isCustomer,
    isPartnerUrl: isPartner,
  } = useConfig();

  const domainTitle =
    (isCustomer && "Espace bénéficiaire") ||
    (isPartner && "Espace partenaire") ||
    "Espace gestionnaire";

  const contextValue = useMemo(
    () => ({
      isCustomer,
      isManager,
      isPartner,
      domainTitle,
    }),
    [isCustomer, isManager, isPartner, domainTitle],
  );
  return (
    <DomainContext.Provider value={contextValue}>
      <HelmetProvider>
        <Helmet titleTemplate="Sorégies &ndash; %s" title={domainTitle} />
        {children}
      </HelmetProvider>
    </DomainContext.Provider>
  );
}

DomainProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
