import { useMemo } from "react";
import useSWR from "swr";
import useFetchApi from "hooks/useFetchApi";
import { stringifyQueryParams } from "functions";

function formatSiret(siret) {
  const [, part1, part2, part3, part4] = siret.match(/(...)(...)(...)(.+)/);
  return `${part1} ${part2} ${part3} ${part4}`;
}

export const getPartenaireLabel = (partenaire) => {
  return `${partenaire.attributes.raison_sociale || "Non renseigné"}${partenaire.attributes.siret ? ` (${formatSiret(partenaire.attributes.siret)})` : ""}`;
};

export default function usePartenairesOptions(defaultOptionLabel = null) {
  const [fetchApi] = useFetchApi();
  const { mutate, data } = useSWR(
    "partenaires?" + stringifyQueryParams({ pagination: { limit: 999999 } }),
    fetchApi,
  );
  const partenairesOptions = useMemo(
    () => [
      { value: null, label: defaultOptionLabel ?? "Aucune entreprise" },
      ...(data?.data ?? [])
        .filter((partenaire) => partenaire.attributes.blocked !== true)
        .map((partenaire) => ({
          label: getPartenaireLabel(partenaire),
          value: partenaire.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    ],
    [data, defaultOptionLabel],
  );
  return useMemo(
    () => ({
      partenairesOptions,
      mutate,
    }),
    [partenairesOptions, mutate],
  );
}
