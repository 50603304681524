import React from "react";
import PropTypes from "prop-types";

export default function Main({
  title,
  header,
  children,
  className,
  innerClassName,
}) {
  return (
    <div
      className={`rounded-xl border-0.5 border-stone-400 flex flex-col overflow-y-hidden ${className || "flex-1"}`}
    >
      <div
        className={`bg-white rounded-xl flex-1 flex flex-col overflow-y-auto ${innerClassName || "p-5"}`}
      >
        {(title || header) && (
          <div className="flex flex-row mb-6">
            {title && (
              <h1 className="font-semibold text-2xl text-main-color">
                {title}
              </h1>
            )}
            {header && (
              <>
                <div className="flex-1"></div> {header}
              </>
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

Main.propTypes = {
  title: PropTypes.any,
  header: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
};
