import React from "react";
import PropTypes from "prop-types";
import { useConfig } from "providers/ConfigProvider";
import {
  statutsDossierGestionnaireToClient,
  statutsDossierGestionnaireColors,
  statutsDossierClientColors,
} from "constants/statutsDossier";

export default function DossierStatus({ status }) {
  const { isManagerUrl } = useConfig();

  const statusText = isManagerUrl
    ? status
    : statutsDossierGestionnaireToClient[status] ?? status;
  const statusColor = isManagerUrl
    ? statutsDossierGestionnaireColors[status]
    : statutsDossierClientColors[statusText] ?? "status-success";

  return (
    <div
      className={`text-center px-2.5 py-1.5 rounded text-white ${statusColor}`}
    >
      {statusText}
    </div>
  );
}
DossierStatus.propTypes = {
  status: PropTypes.string.isRequired,
};
