import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "components/AuthProvider";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { ReactComponent as CheckIcon } from "images/icons/check.svg";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";
import { translateMessage } from "functions";
import { Link } from "react-router-dom";
import Input from "components/ParametreTabs/Input";

export default function ChangePasswordDialog({ onClose }) {
  const [fetchApi] = useFetchApi();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [passwordResetLoading, setPasswordResetLoading] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [passwordResetError, setPasswordResetError] = useState(null);
  const { user } = useAuth();
  const onSubmit = async (data) => {
    document.activeElement?.blur();
    // We blur the active form so that the user can't submit it twice
    // by pressing the enter key, when showing the overlay.
    document.activeElement?.blur();
    try {
      setLoading(true);
      await fetchApi("auth/change-password", {
        body: data,
      });
      // Trigger a revalidation (refetch).
      mutate("/api/auth/local");
      setSuccess(true);
    } catch ({ message, name, details }) {
      const translatedMessage = translateMessage(message);
      if (translatedMessage === message && name === "ValidationError") {
        setError("Veuillez renseigner tous les champs.");
      } else {
        setError(translatedMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async () => {
    if (passwordResetLoading) {
      return;
    }
    setPasswordResetLoading(true);
    setPasswordResetSuccess(false);
    setPasswordResetError(null);
    try {
      await fetchApi("auth/forgot-password", {
        body: { email: user.email },
      });
      setPasswordResetSuccess(true);
    } catch (e) {
      console.error(e);
      setPasswordResetError(e.message);
    }
    setPasswordResetLoading(false);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[80%] max-w-[537px] p-8 grid grid-cols-2 bg-white rounded border relative gap-3"
    >
      <button
        type="button"
        onClick={onClose}
        className="text-xs underline absolute right-4 top-4 z-20"
      >
        Fermer
      </button>
      <div className="col-span-2 text-center mt-3">
        <h2 className="text-2xl font-semibold text-main-color mb-3">
          Changement de mot de passe
        </h2>
      </div>
      <div className="col-span-2">
        <Input
          label="Mot de passe actuel"
          name="currentPassword"
          type="password"
          register={register}
        />
      </div>

      <div className="col-span-2">
        <h2 className="text-base font-semibold text-main-color mt-3">
          Changement de mot de passe
        </h2>
      </div>

      <div className="col-span-2">
        <Input
          label="Nouveau mot de passe"
          name="password"
          type="password"
          register={register}
        />
      </div>
      <div className="col-span-2">
        <Input
          label="Confirmer nouveau mot de passe"
          name="passwordConfirmation"
          type="password"
          register={register}
        />
      </div>

      {!passwordResetSuccess && (
        <p className="col-span-2 text-xs text-light-color">
          Mot de passe oublié ?{" "}
          <Link
            onClick={resetPassword}
            disabled={passwordResetLoading}
            className="underline underline-offset-2 hover:text-gray-500"
          >
            Réinitialiser mon mot de passe
          </Link>
          {passwordResetLoading && (
            <LoadingIcon className="inline-block ml-1 w-3 animate-spin" />
          )}
        </p>
      )}
      {passwordResetError && (
        <p className="col-span-2 text-xs text-red-500">{passwordResetError}</p>
      )}
      {passwordResetSuccess && (
        <p className="col-span-2 text-xs text-green">
          Un e-mail pour réinitialiser votre mot de passe vous a été envoyé.
        </p>
      )}

      <div className="col-span-2">
        <Button
          label="Valider modification"
          className="mx-auto mt-4"
          variant="contained"
          type="submit"
        />
      </div>
      {error && (
        <div className="text-red-500 text-center col-span-2">
          <p className="my-4 text-sm font-semibold">{error}</p>
        </div>
      )}
      {(loading || success) && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-white rounded flex items-center justify-center p-5">
          {loading && (
            <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
          )}
          {success && (
            <div className="flex text-main-color items-center gap-6">
              <CheckIcon />
              <div className="text-center text-2xl font-semibold">
                Le mot de passe a bien été changé
              </div>
            </div>
          )}
        </div>
      )}
    </form>
  );
}
ChangePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};
