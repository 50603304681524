import React from "react";
import { useAuth } from "components/AuthProvider";
import { Link } from "react-router-dom";
import DossierList from "components/DossierList";

export default function LastViewFiles() {
  const { isCustomer } = useAuth();
  return (
    <div className="w-full pr-3">
      <div className="flex flex-row mb-5">
        <h3 className="font-semibold text-2xl text-main-color">
          {isCustomer
            ? "Mes derniers projets consultés"
            : "Derniers dossiers consultés"}
        </h3>
        <div className="flex-1"></div>
        <div className="flex items-center max-w-[60px] w-full">
          <Link to={isCustomer ? "/mes-projets" : "/dossiers"}>
            <button className="text-sm text-main-color underline">
              Voir tout
            </button>
          </Link>
        </div>
      </div>

      <DossierList
        lastView
        headers={false}
        pagination={false}
        pageSize={5}
        flex={false}
      />
    </div>
  );
}
