import React, { useState } from "react";
import PropTypes from "prop-types";
import iconEdit from "images/icons/edit.svg";
import iconConfirm from "images/icons/confirm.svg";
import iconCancel from "images/icons/cancel.svg";
import useSWR, { mutate } from "swr";
import useFetchApi from "hooks/useFetchApi";
import Loader from "components/Loader";

export default function Tarifs() {
  const [fetchApi] = useFetchApi();
  const { data, error, isLoading } = useSWR("valorisation", fetchApi);

  function FieldBlockTarif({ name, value }) {
    const [localvalue, setLocalValue] = useState(value);
    const unit = " €";
    const [edit, setEdit] = useState(false);

    const onSubmit = async () => {
      document.activeElement?.blur();
      const body = { [name]: Number(localvalue) };
      if (localvalue) {
        try {
          await fetchApi(`update-valorisation`, { method: "PUT", body });
          // Trigger a revalidation (refetch) of the "Dossiers details" list.
          mutate(`valorisation`);
          // onClose();
        } catch ({ message }) {
          // setError(message);
        } finally {
          setEdit(false);
        }
      } else {
        setEdit(false);
      }
    };

    return (
      <div className="flex justify-between items-center">
        {/* <p>{localvalue}</p> */}
        {!edit && `${value} ${unit}`}
        {edit && (
          <input
            type="text"
            value={localvalue}
            className='text-main-color w-[100px] p-1 border-1 border-2 border-sky-600 rounded outline-none"'
            onChange={(e) =>
              setLocalValue(e.target.value.replace(/[^0-9]/g, ""))
            }
          />
        )}
        {!edit && (
          <div className="flex space-x-1">
            <button onClick={() => setEdit(!edit)}>
              <img src={iconEdit} alt="modifier" />
            </button>
          </div>
        )}

        {edit && (
          <div className="flex space-x-1">
            <button onClick={onSubmit}>
              <img src={iconConfirm} alt="confirmer" />
            </button>
            <button
              onClick={() => {
                setEdit(!edit);
                setLocalValue(value);
              }}
            >
              <img src={iconCancel} alt="annuler" />
            </button>
          </div>
        )}
      </div>
    );
  }
  FieldBlockTarif.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  };

  function Row({ status, cdp, name, value }) {
    // console.log('value', value)
    return (
      <div
        className={`flex border-stone-400 border-0.5 rounded-lg px-4 py-5 text-xs text-light-color mt-2 items-center`}
      >
        <div className="w-[200px] pl-2 text-black">{status}</div>
        <div className="w-[200px] pl-2">{cdp ? "Oui" : "Non"}</div>
        <div className="w-[200px] pl-2">
          <FieldBlockTarif name={name} value={value} />
        </div>
      </div>
    );
  }
  Row.propTypes = {
    status: PropTypes.string.isRequired,
    cdp: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  };

  if (isLoading || error) {
    return <Loader isLoading={isLoading} error={error} />;
  } else {
    return (
      <div className="w-[600px] mt-6">
        <div className="flex text-xs text-[#9E9E9E] px-3">
          <div className="w-[200px] pl-2">Statut précarité</div>
          <div className="w-[200px] pl-2">Coup de pouce</div>
          <div className="w-[200px] pl-2">Prix (au KWhc)</div>
        </div>

        <Row
          status="Classique"
          cdp={false}
          name="valorisation_classique"
          value={data?.valorisation_classique}
        />
        <Row
          status="Classique"
          cdp={true}
          name="valorisation_classique_cdp"
          value={data?.valorisation_classique_cdp}
        />
        <Row
          status="Précaire"
          cdp={false}
          name="valorisation_precaire"
          value={data?.valorisation_precaire}
        />
        <Row
          status="Précaire"
          cdp={true}
          name="valorisation_precaire_cdp"
          value={data?.valorisation_precaire_cdp}
        />
      </div>
    );
  }
}
