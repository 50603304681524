export default [
  "Chaudière bois",
  "Poêle ou insert bois",
  "Chauffage et/ou eau chaude solaire",
  "Pompe à chaleur : chauffage",
  "Chauffe-eau thermodynamique",
  "Chaudière condensation ou micro-cogénération gaz ou fioul",
  "Fenêtres, volets, portes donnant sur l’extérieur",
  "Fenêtres de toit",
  "Radiateurs électriques, dont régulation",
  "Isolation",
];
