import React, { useState } from "react";
import Menu from "components/Menu";
import { ReactComponent as PlusIcon } from "images/icons/plus.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button";
import NewFileDialog from "components/NewFileDialog";
import { useAuth } from "components/AuthProvider";
import NotificationButton from "components/NotificationButton";

export default function App() {
  const navigate = useNavigate();
  const [showNewFileDialog, setShowNewFileDialog] = useState(false);
  const auth = useAuth();
  const location = useLocation();
  const role = auth?.user?.role?.name;
  const isMesSimulations = location.pathname.includes("mes-simulations");
  const { isCustomer, isManager } = auth;

  return (
    <div className="h-full flex row items-center justify-center">
      <Menu className="w-[14rem] h-full" role={role} />
      <div className="flex-1 h-full bg-stone-100 overflow-y-auto flex flex-col">
        <div className="h-full p-4 flex flex-col flex-1 gap-4 relative">
          <div className="flex justify-end">
            {isManager && role !== "Consultation" && !isMesSimulations && (
              <Button
                label="Créer un dossier"
                icon={<PlusIcon />}
                variant="contained"
                color="orange"
                onClick={() => setShowNewFileDialog(true)}
                className="text-sm"
              />
            )}
            {isCustomer && (
              <Button
                label="Faire une nouvelle simulation"
                icon={<PlusIcon />}
                variant="contained"
                color="orange"
                onClick={() => {
                  if (
                    location.pathname.includes("mes-simulations/simulateur")
                  ) {
                    window.location.reload();
                  } else {
                    navigate("mes-simulations/simulateur");
                  }
                }}
                className="text-sm"
              />
            )}

            <NotificationButton userID={auth.user.id} />
          </div>
          <Outlet context={role} />
          {showNewFileDialog ? (
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-90 flex items-center justify-center">
              <NewFileDialog onClose={() => setShowNewFileDialog(false)} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
