import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { ReactComponent as InfoIcon } from "images/icons/informations.svg";

const FADE_DELAY = 200;

export default function InfoTooltip({ text }) {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const hideTimer = useRef();

  const setOpenTooltip = (visible) => {
    clearTimeout(hideTimer.current);
    if (visible) {
      setOpen(true);
      hideTimer.current = setTimeout(() => setHover(true), 0);
    } else {
      setHover(false);
      hideTimer.current = setTimeout(() => setOpen(false), FADE_DELAY);
    }
  };

  return (
    <div className="relative mr-2 inline-block align-middle h-5">
      <InfoIcon
        className="h-5 cursor-help"
        onMouseEnter={() => setOpenTooltip(true)}
        onMouseLeave={() => setOpenTooltip(false)}
        onClick={() => setOpenTooltip(!hover)}
      />
      {open && (
        <div
          className={`select-none absolute px-2.5 py-1.5 rounded text-white bg-main-color min-w-[480px] transition transition-opacity ease-in-out duration-[${FADE_DELAY}ms] ${hover ? "opacity-100" : "opacity-0"}`}
        >
          {text}
        </div>
      )}
    </div>
  );
}

InfoTooltip.propTypes = {
  text: PropTypes.string.isRequired,
};
