import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { translateMessage } from "functions";
import FieldBlock from "components/FieldBlock";
import useFetchApi from "hooks/useFetchApi";
import InfoTooltip from "components/InfoTooltip";

export default function FieldBlockText({
  label,
  name,
  type = "text",
  value,
  id,
  route,
  sectionID,
  readonly,
  require,
  editAll,
  updateGroupAnswers,
  maxLength,
  minLength,
  decimals,
  nospace,
  fetchData,
  tooltip,
  format,
}) {
  const [fetchApi] = useFetchApi();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(value);
  const [fieldError, setFieldError] = useState();
  const isNumeric =
    type === "number" || type === "float" || type === "decimalnumber";
  const isLengthValid = !minLength || !answer || answer?.length >= minLength;
  const isRequireValid = !require || !!answer;
  const isValid = isLengthValid && isRequireValid;

  const onSubmit = async () => {
    if (!isValid) {
      return;
    }
    setLoading(true);
    document.activeElement?.blur();
    const body = route
      ? { [route]: { [name]: answer || null, id: sectionID } }
      : { [name]: answer || null };

    try {
      setFieldError(null);
      await fetchApi(`update-dossier/${id}`, { method: "PUT", body });
      fetchData();
    } catch ({ message }) {
      setFieldError(translateMessage(message));
      setAnswer(value);
    }
    setLoading(false);
    setEditing(false);
  };

  const onCancel = () => {
    setEditing(false);
    setAnswer(value);
  };

  const handleAnswer = (item) => {
    setAnswer(item);
    updateGroupAnswers(name, item, route, sectionID);
  };

  const saveAnswer = editAll ? handleAnswer : setAnswer;

  useEffect(() => {
    setAnswer(value);
  }, [value]);

  useEffect(() => {
    if (!editAll) {
      setEditing(false);
    }
  }, [editAll]);

  const inputProps = {
    type: type || "text",
    value: answer || "",
    onChange: (e) => {
      let value = e.target.value;
      if (nospace || isNumeric) {
        value = value.replace(/\s/g, "");
      }
      if (type === "number") {
        value = value.match(/(\d*)/)?.[1] ?? "";
      } else if (type === "float") {
        value = value.match(/(\d*\.?\d*)/)?.[1] ?? "";
      } else if (type === "decimalnumber") {
        value =
          value.match(
            new RegExp(
              `\\d{0,${maxLength || 10}}(\\.\\d{0,${decimals || 2}})?`,
              "g",
            ),
          )?.[0] ?? "";
      }
      saveAnswer(value);
    },
    autoFocus: !editAll,
    maxLength,
    minLength,
    disabled: loading,
  };

  if (isNumeric) {
    inputProps.type = "text";
    if (type === "decimalnumber") {
      delete inputProps.maxLength;
    }
  }

  return (
    <FieldBlock
      label={
        label && (
          <>
            {label} {name === "volume_silo" && <span>(en litres)</span>}
            {tooltip && <InfoTooltip text={tooltip} />}
          </>
        )
      }
      displayValue={format ? format(value) : value}
      loading={loading}
      editing={editing}
      editAll={editAll}
      readonly={readonly}
      onSubmit={onSubmit}
      onCancel={onCancel}
      setEditing={setEditing}
    >
      <input
        {...inputProps}
        className="w-[570px] border-2 border-sky-600 rounded outline-none p-1"
      />
      {fieldError && <p className="text-xs text-red-500">{fieldError}</p>}
      {!isLengthValid && (
        <p className="text-red-500 text-xs mt-1">
          La saisie doit contenir
          {minLength !== maxLength ? " au moins " : " "}
          {minLength} caractères.
        </p>
      )}
      {!isRequireValid && !answer && (
        <p className="text-red-500 text-xs mt-1">
          * Attention ce champ est obligatoire
        </p>
      )}
    </FieldBlock>
  );
}
FieldBlockText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  id: PropTypes.number,
  route: PropTypes.string,
  sectionID: PropTypes.number,
  readonly: PropTypes.bool,
  require: PropTypes.bool,
  editAll: PropTypes.bool,
  updateGroupAnswers: PropTypes.func,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  decimals: PropTypes.number,
  nospace: PropTypes.bool,
  fetchData: PropTypes.func,
  tooltip: PropTypes.string,
  format: PropTypes.func,
};
