import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export default function Checkbox({
  indeterminate,
  onChange,
  disabled,
  ...props
}) {
  const ref = useRef();
  useEffect(() => {
    // https://css-tricks.com/indeterminate-checkboxes/
    ref.current.indeterminate = indeterminate ?? false;
  }, [indeterminate]);
  return (
    <input
      {...props}
      ref={ref}
      type="checkbox"
      className="h-4 w-4 rounded-sm accent-blue align-middle"
      onChange={(e) => onChange?.(e.target.checked)}
      disabled={disabled ?? false}
    />
  );
}
Checkbox.propTypes = {
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
Checkbox.defaultProps = {
  indeterminate: false,
  disabled: false,
};
