import React, { useState } from "react";
import Button from "components/Button";
import useFetchApi from "hooks/useFetchApi";
import { translateMessage } from "functions";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";

export default function ImportPartenaires() {
  const [fetchApi] = useFetchApi();
  const [csv, setCsv] = useState();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [importResult, setImportResult] = useState();
  const [error, setError] = useState();

  const importPartenaires = async () => {
    try {
      setLoading(true);
      setSuccess(null);
      setError(null);
      setImportResult(null);
      const result = await fetchApi("partenaires/import", { body: { csv } });
      setImportResult(result);
      setSuccess(true);
    } catch (error) {
      setError(translateMessage(error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <h2 className="text-main-color font-bold mt-4">
        Import des entreprises partenaires
      </h2>
      <p className="mt-1 text-md">
        Importer un fichier CSV séparé par des tabulations.
      </p>

      <textarea
        className="w-full border border-black mt-4 grow min-h-40"
        onChange={(event) => setCsv(event.currentTarget.value)}
      />

      <p className="mt-1 text-xs">Formatage de l&apos;en-tête :</p>
      <ul className="ml-4 text-xs">
        <li>
          <b>Première ligne :</b> SIRET, SIREN, Raison sociale pro., Titre (M. /
          Mme), Nom, Prénom, Adresse postale, Adresse email, RGE (Oui / Non / A
          vérifier), Domaines de travaux (TRUE / FALSE)
        </li>
        <li>
          <b>Deuxième ligne :</b> 9 cellules vides suivies d&apos;une cellule
          par domaine de travaux.
        </li>
      </ul>

      <div className="mt-4">
        <Button
          label="Importer les partenaires"
          icon={loading ? <LoadingIcon /> : null}
          disabled={loading}
          onClick={() => {
            importPartenaires(true);
          }}
        />
      </div>

      {error && (
        <p className="mt-4 font-semibold text-md text-red-500">{error}</p>
      )}

      {success && (
        <div className="mt-4 text-md">
          {importResult.imported > 0 && (
            <p className="text-green">
              Nouvelles entreprises partenaires importées :{" "}
              {importResult.imported}
            </p>
          )}
          {importResult.usersCreated > 0 && (
            <p className="text-green">
              Nouveaux utilisateurs créés : {importResult.usersCreated}
            </p>
          )}
          {importResult.updated > 0 && (
            <p className="text-green">
              Entreprises partenaire mises à jour : {importResult.updated}
            </p>
          )}
          {importResult.failed > 0 && (
            <p className="text-red-500">
              Imports échoués : {importResult.failed}
            </p>
          )}
          {importResult.failed > 0 && (
            <ul className="text-red-500 ml-4 text-xs">
              {importResult.errors.map(({ row, message }) => (
                <li key={`${row}-${message}`}>
                  Ligne {row} : {translateMessage(message)}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
}
