import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAuth } from "components/AuthProvider";
import FieldBlockText from "components/FieldBlockText";
import FieldBlockSelect from "components/FieldBlockSelect";
import FieldBlockDate from "components/FieldBlockDate";
import FieldBlockCheckbox from "components/FieldBlockCheckbox";
import iconCancel from "images/icons/cancel.svg";
import iconConfirm from "images/icons/confirm.svg";
import useFetchApi from "hooks/useFetchApi";

export default function TabForm({ tab, id, error, fetchData }) {
  const [fetchApi] = useFetchApi();
  const { isPartner, role } = useAuth();
  const [editAll, setEditAll] = useState(false);
  const [groupAnswers, setGroupAnswers] = useState({});
  const readonly = isPartner || role === "Consultation";
  const submitAll = async () => {
    try {
      await fetchApi(`update-dossier/${id}`, {
        method: "PUT",
        body: groupAnswers,
      });
      // Trigger a revalidation (refetch) of the "Dossiers details" list.
      fetchData();
    } catch ({ message }) {
      console.warn(message);
    } finally {
      setEditAll(false);
    }
  };

  // Vérifie qu'on ait au moins un champ éditable dans tout le formulaire
  const editableFields = tab.fields.filter(
    (field) => field.type !== "readonly",
  ).length;

  const updateGroupAnswers = (name, value, route, sectionID) => {
    setGroupAnswers((prevGroupAnswers) => {
      const ANS = { ...prevGroupAnswers };
      if (route) {
        if (ANS[route]) {
          ANS[route][name] = value;
        } else {
          ANS[route] = { [name]: value, id: sectionID };
        }
      } else {
        ANS[name] = value;
      }
      return ANS; // Return the updated state
    });
  };

  return (
    <div>
      {tab.fields
        .filter((field) => field.visible === undefined || !!field.visible)
        .map((field, index) =>
          field.type === "select" ? (
            <FieldBlockSelect
              {...field}
              key={index}
              id={id}
              editAll={editAll && !field.noEditAll}
              updateGroupAnswers={updateGroupAnswers}
              readonly={readonly}
              fetchData={fetchData}
            />
          ) : field.type === "date" ? (
            <FieldBlockDate
              {...field}
              key={index}
              id={id}
              editAll={editAll && !field.noEditAll}
              updateGroupAnswers={updateGroupAnswers}
              readonly={readonly}
              fetchData={fetchData}
            />
          ) : field.type === "checkbox" ? (
            <FieldBlockCheckbox
              {...field}
              key={index}
              id={id}
              editAll={editAll && !field.noEditAll}
              updateGroupAnswers={updateGroupAnswers}
              readonly={readonly}
              fetchData={fetchData}
            />
          ) : (
            <FieldBlockText
              {...field}
              key={index}
              id={id}
              editAll={editAll && !field.noEditAll}
              readonly={field.type === "readonly" || readonly}
              updateGroupAnswers={updateGroupAnswers}
              fetchData={fetchData}
            />
          ),
        )}

      {editableFields > 1 && (
        <div className="flex justify-end mt-1">
          {editAll && !readonly && (
            <div className="flex space-x-1">
              <button onClick={submitAll}>
                <img src={iconConfirm} alt="confirmer" />
              </button>
              <button onClick={() => setEditAll(false)}>
                <img src={iconCancel} alt="annuler" />
              </button>
            </div>
          )}

          {!editAll && !readonly && tab.fields.length > 1 && (
            <button
              onClick={() => setEditAll(true)}
              className="text-xs py-1 font-semibold text-main-color"
            >
              Tout modifier
            </button>
          )}
        </div>
      )}
      {error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
}
TabForm.propTypes = {
  tab: PropTypes.instanceOf(Object).isRequired,
  id: PropTypes.number.isRequired,
  error: PropTypes.string,
  fetchData: PropTypes.func.isRequired,
};
