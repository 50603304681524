import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import ArrowUp from "images/icons/arrowUp.svg";
import Main from "components/Main";

export default function PageAccordion({ filename, title }) {
  const [content, setContent] = useState("");
  useEffect(() => {
    fetch(filename)
      .then((res) => res.text())
      .then((text) => setContent(text));
  }, [filename]);

  const sections = useMemo(
    () =>
      content
        .split(/\[\[(.*?)\]\]/)
        .filter(Boolean)
        .map((section, index, array) => {
          if (index % 2 === 0) {
            return {
              title: section.trim(),
              content: array[index + 1],
            };
          }
          return null;
        })
        .filter(Boolean),
    [content],
  );
  const [openIndex, setOpenIndex] = useState(0);

  const handleAccordionClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Main title={title}>
      {sections.map((section, index) => (
        <div key={index} className="mb-3 border border-[#DCF0FF] rounded-md">
          <div
            className="w-full bg-light-color p-3 cursor-pointer rounded-md text-lg flex justify-between"
            onClick={() => handleAccordionClick(index)}
          >
            <span>{section.title}</span>
            <img
              src={ArrowUp}
              className={openIndex === index ? "rotate-180" : "rotate-90"}
              alt="arrow"
            />
          </div>
          {openIndex === index && (
            <div className="p-3">
              <ReactMarkdown className="markdown accordin">
                {section.content}
              </ReactMarkdown>
            </div>
          )}
        </div>
      ))}
    </Main>
  );
}
PageAccordion.propTypes = {
  filename: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
