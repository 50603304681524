import { React } from "react";
import Infographic from "components/Indicateur/Infographic";
import VolumeFost from "components/Indicateur/VolumeFost";
import IndicateurTableau from "components/Indicateur/IndicateurTableau";
import Main from "components/Main";

export default function Indicateurs() {
  return (
    <Main className="fadeIn flex-1">
      {/* <div className="flex flex-row m-6">
        <h1 className="font-semibold text-2xl text-main-color">Indicateurs</h1>
    </div> */}
      <Infographic />
      <VolumeFost />
      <IndicateurTableau />
    </Main>
  );
}
