import React from "react";
import PropTypes from "prop-types";
import useSWR from "swr";
import useFetchApi from "hooks/useFetchApi";

function FostBlock({ label, number, biggestNumber }) {
  const replaceList = {
    "BAR-TH-113 Chaudière biomasse":
      "BAR-TH-113 Chaudière biomasse individuelle",
    "BAR-TH-113 Coup de pouce Chaudière biomasse":
      "BAR-TH-113 Coup de pouce Chaudière biomasse individuelle",
    "FOST à renseigner null": "FOST à renseigner",
  };
  const replaceLabel = replaceList[label] ? replaceList[label] : label;
  const num = number ?? 0;
  const barWidth = `${(num / biggestNumber).toFixed(4) * 100}%`;
  return (
    <div className="flex w-full items-center fadeIn px-4">
      <div className="text-xs w-[850px]">{replaceLabel}</div>
      <div className="w-full p-1">
        <div
          className="bg-light-color rounded-r pr-4 pl-1 text-main-color text-xs text-right animated-bar"
          style={{ width: barWidth }}
        >
          {num.toLocaleString("fr-FR", { currency: "EUR" })}
        </div>
      </div>
    </div>
  );
}
FostBlock.propTypes = {
  label: PropTypes.string,
  number: PropTypes.number,
  biggestNumber: PropTypes.number,
};

export default function VolumeFost() {
  const [fetchApi] = useFetchApi();
  const { data, error, isLoading } = useSWR("indicateurs", fetchApi);

  if (!data) {
    return (
      <div className="w-100 my-6">
        <div className="div1 border-stone-400 border-0.5 rounded-lg px-4 py-4 min-h-[235px]">
          <h1 className="text-base font-semibold mb-3 px-4">Volumes en kWhc</h1>

          <div className="min-h-[250px] flex-1 flex justify-center items-center px-4">
            {error && !isLoading ? (
              <div className="text-red-500">
                Une erreur est survenue: {error.message}
              </div>
            ) : null}
            {isLoading && (
              <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    const fostList = data.data.indicators.volumes;

    const sortedFostList = Object.entries(fostList).sort((a, b) => b[1] - a[1]);
    const biggestNumber = sortedFostList[0] ? sortedFostList[0][1] : null;
    return (
      <div className="w-100 my-6">
        <div className="div1 border-stone-400 border-0.5 rounded-lg py-4 min-h-[235px]">
          <h1 className="text-base font-semibold mb-3 px-4">Volumes en kWhc</h1>
          {sortedFostList.map(([label, number]) => (
            <FostBlock
              key={label}
              label={label}
              number={number}
              biggestNumber={biggestNumber}
            />
          ))}
        </div>
      </div>
    );
  }
}
