const GREEN = "bg-green";
const BLUE = "bg-blue";
const ORANGE = "bg-orange";
const GRAY = "bg-status-disabled";
const RED = "bg-status-error";

export const statutsDossierGestionnaire = [
  {
    name: "Nouvelle demande à traiter",
    color: GREEN,
    wider: true,
  },
  {
    name: "Devis validé",
    color: GREEN,
  },
  {
    name: "RAI envoyé",
    color: GREEN,
  },
  {
    name: "Travaux en cours",
    color: GREEN,
  },
  {
    name: "Travaux terminés",
    color: GREEN,
  },
  {
    name: "Documents reçus et à traiter",
    color: GREEN,
    wider: true,
  },
  {
    name: "Litige",
    color: RED,
  },
  {
    name: "Documents validés",
    color: GREEN,
    wider: true,
  },
  {
    name: "Contrôle chantier",
    color: GREEN,
  },
  {
    name: "Chantier non conforme",
    color: RED,
  },
  {
    name: "Chantier conforme",
    color: GREEN,
    wider: true,
  },
  {
    name: "Dossier validé",
    color: GREEN,
  },
  {
    name: "Prime demandée",
    color: GREEN,
    wider: true,
  },
  {
    name: "Prime versée",
    color: GREEN,
  },
  {
    name: "Dossier déposé",
    color: GREEN,
  },
  {
    name: "Abandon",
    color: RED,
  },
];

export default statutsDossierGestionnaire;

export const statutsDossierGestionnaireColors = Object.fromEntries(
  statutsDossierGestionnaire.map((status) => [status.name, status.color]),
);

export const statutsDossierClient = [
  {
    name: "Nouvelle demande",
    color: BLUE,
    wider: true,
  },
  {
    name: "Devis validé",
    color: GREEN,
  },
  {
    name: "Travaux en cours",
    color: ORANGE,
  },
  {
    name: "Dossier en cours de traitement",
    color: ORANGE,
    wider: true,
  },
  {
    name: "Contrôle chantier",
    color: ORANGE,
    wider: true,
  },
  {
    name: "Dossier validé",
    color: GREEN,
    wider: true,
  },
  {
    name: "Prime versée",
    color: GREEN,
  },
  {
    name: "Abandon",
    color: GRAY,
  },
];

export const statutsDossierClientColors = Object.fromEntries(
  statutsDossierClient.map((status) => [status.name, status.color]),
);

export const statutsDossierGestionnaireToClient = {
  "Nouvelle demande à traiter": "Nouvelle demande",
  "Devis validé": "Devis validé",
  "RAI envoyé": "Devis validé",
  "Travaux en cours": "Travaux en cours",
  "Travaux terminés": "Travaux en cours",
  "Documents reçus et à traiter": "Dossier en cours de traitement",
  Litige: "Dossier en cours de traitement",
  "Documents validés": "Dossier en cours de traitement",
  "Contrôle chantier": "Contrôle chantier",
  "Chantier non conforme": "Contrôle chantier",
  "Chantier conforme": "Contrôle chantier",
  "Dossier validé": "Dossier validé",
  "Prime demandée": "Dossier validé",
  "Prime versée": "Prime versée",
  "Dossier déposé": "Prime versée",
  Abandon: "Abandon",
};
