import React from "react";
import PropTypes from "prop-types";
import { useAuth } from "components/AuthProvider";
import { Link, useLocation } from "react-router-dom";
import logo from "images/logo_soregies.svg";
import { ReactComponent as AccountIcon } from "images/icons/account.svg";
import { ReactComponent as AideIcon } from "images/icons/aide.svg";
import { ReactComponent as FileIcon } from "images/icons/file.svg";
import { ReactComponent as HomeIcon } from "images/icons/home.svg";
import { ReactComponent as LogoutIcon } from "images/icons/logout.svg";
import { ReactComponent as ClientIcon } from "images/icons/client.svg";
// import { ReactComponent as NewsIcon } from "images/icons/news.svg";
import { ReactComponent as SettingsIcon } from "images/icons/settings.svg";
import { ReactComponent as DepotIcon } from "images/icons/depot.svg";
import { ReactComponent as EuroIcon } from "images/icons/prime.svg";
import { ReactComponent as IndicateurIcon } from "images/icons/indicateur.svg";
import { ReactComponent as ReportingIcon } from "images/icons/reporting.svg";
import { ReactComponent as SimulationIcon } from "images/icons/simulation.svg";
import { ReactComponent as RgeIcon } from "images/icons/rge.svg";
import { ReactComponent as EmailIcon } from "images/icons/c-mail.svg";
import { ReactComponent as PhoneIcon } from "images/icons/c-phone.svg";

function MenuItem({ to, logo, label, activePaths, external = false }) {
  const Logo = logo;
  const location = useLocation();
  let active =
    to === "/" ? location.pathname === "/" : location.pathname.startsWith(to);
  if (activePaths && !active) {
    active = activePaths.some((path) => location.pathname.startsWith(path));
  }
  const extraProps = external ? { target: "_blank", rel: "noreferrer" } : {};

  return (
    <Link
      to={to}
      className={`flex items-center w-full ${
        active ? "bg-main-color text-white" : "text-main-color"
      } gap-4 h-12 rounded-lg pl-2 text-sm `}
      {...extraProps}
    >
      <Logo />
      <span className="font-medium">{label}</span>
    </Link>
  );
}

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  logo: PropTypes.any.isRequired,
  label: PropTypes.any.isRequired,
  activePaths: PropTypes.array,
  external: PropTypes.bool,
};

export default function Menu({ className, role }) {
  const { isCustomer, isManager, isPartner } = useAuth();
  return (
    <div
      className={`bg-light-color py-10 flex flex-col pl-4 pr-2 items-center ${className}`}
      style={{ zIndex: "9999999" }}
    >
      <img src={logo} alt="logo Sorégies Groupe" />
      <h1 className="mb-10 mt-3 font-semibold text-sm text-main-color">
        {(isCustomer && <>Espace bénéficiaire</>) ||
          (isManager && <>Espace gestionnaire CEE</>) ||
          (isPartner && <>Espace partenaire</>) || <></>}
      </h1>
      <MenuItem
        activePaths={isPartner ? ["/dossiers"] : undefined}
        to="/"
        logo={HomeIcon}
        label="Accueil"
        active
      />
      {isManager && (
        <MenuItem
          to="/simulations-clients"
          logo={ClientIcon}
          label="Simulations Clients"
        />
      )}
      {isManager && (
        <MenuItem to="/dossiers" logo={FileIcon} label="Mes dossiers" />
      )}
      {isManager && (
        <MenuItem to="/reporting" logo={ReportingIcon} label="Reporting" />
      )}

      {isManager && (
        <MenuItem to="/indicateurs" logo={IndicateurIcon} label="Indicateurs" />
      )}

      {isCustomer && (
        <MenuItem
          to="/mes-simulations"
          logo={SimulationIcon}
          label="Mes simulations"
        />
      )}

      {isCustomer && (
        <MenuItem to="/mes-projets" logo={FileIcon} label="Mes projets" />
      )}

      {isManager && role !== "Consultation" && (
        <MenuItem to="/depot-emmy" logo={DepotIcon} label="Dépôt Emmy" />
      )}
      {isManager && role !== "Consultation" && (
        <MenuItem
          to="/verser-une-prime"
          logo={EuroIcon}
          label="Verser une prime"
        />
      )}

      {/* <MenuItem to="/actualite" logo={NewsIcon} label="Actualité" /> */}
      <div className="flex-1"></div>

      {isPartner && (
        <>
          <div className={"w-full text-xs items-center flex gap-4 mb-2"}>
            <EmailIcon />
            <a
              className={"text-main-color"}
              href="mailto:soregies-cee@soregies.fr"
            >
              soregies-cee@soregies.fr
            </a>
          </div>
          <div className={"w-full text-xs  items-center flex gap-4 mb-4"}>
            <PhoneIcon className="w-[25px] h-[25px]" />
            <div className=" items-start justify-center flex  flex-col">
              <a className={"text-main-color"} href="tel:+330549605424">
                05 49 60 54 24
              </a>
              <a className={"text-main-color"} href="tel:+330549447085">
                05 49 44 70 85
              </a>
            </div>
          </div>
        </>
      )}

      <MenuItem to="/compte" logo={AccountIcon} label="Mon compte" />
      {isManager && role === "Administrateur" && (
        <MenuItem to="/parametres/" logo={SettingsIcon} label="Paramètres" />
      )}

      {isCustomer && (
        <MenuItem
          external
          to="https://www.soregies.fr/concretiser-vos-travaux-partenaires-rge/"
          logo={RgeIcon}
          label="Nos partenaires RGE"
        />
      )}
      {isCustomer && (
        <MenuItem
          external
          to="https://www.soregies.fr/faq/"
          logo={AideIcon}
          label="Aide"
        />
      )}

      <div className="h-5"></div>
      <MenuItem to="/deconnexion" logo={LogoutIcon} label="Se déconnecter" />
    </div>
  );
}

Menu.propTypes = {
  className: PropTypes.string,
  role: PropTypes.string,
};

Menu.defaultProps = {
  className: "",
  role: null,
};
