import React from "react";
import PropTypes from "prop-types";

export default function Cell({ innerClassName, className, children }) {
  return (
    <td className={`h-0 pb-2 ${className}`}>
      <div
        className={`h-[60px] flex items-center border-y-0.5 border-stone-400 py-fix bg-white ${innerClassName}`}
      >
        {children}
      </div>
    </td>
  );
}
Cell.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
};
Cell.defaultProps = {
  className: "",
  innerClassName: "",
};
