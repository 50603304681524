import React, { useState } from "react";
import PropTypes from "prop-types";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";
import StatusTimeline from "components/StatusTimeline";

const statusList = [
  { id: 1, label: "Nouveau" },
  { id: 2, label: "Contacté" },
  { id: 3, label: "Demande de transformation" },
  { id: 4, label: "Converti" },
  { id: 5, label: "Abandon" },
];

const StatusClient = ({ status, role, id }) => {
  const [error, setError] = useState("");
  const [fetchApi] = useFetchApi();

  const updateStatus = async (id, newStatus) => {
    await fetchApi(`simulations/${id}`, {
      method: "PUT",
      body: {
        data: {
          statut: newStatus,
        },
      },
    });
    mutate(`simulations/${id}?populate=*`);
  };

  return (
    <StatusTimeline
      id={id}
      role={role}
      statusList={statusList}
      status={status}
      updateStatus={updateStatus}
      error={error}
      setError={setError}
    />
  );
};
StatusClient.propTypes = {
  status: PropTypes.string,
  role: PropTypes.string,
  id: PropTypes.string,
};

export default StatusClient;
