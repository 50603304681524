import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import FieldBlock from "components/FieldBlock";
import useFetchApi from "hooks/useFetchApi";

export default function FieldBlockSelect({
  label,
  name,
  value,
  id,
  route,
  sectionID,
  editAll = false,
  updateGroupAnswers,
  fetchData,
  multiple = false,
  options,
  emptyLabel = "Sélectionner",
  readonly,
}) {
  const [fetchApi] = useFetchApi();

  const availableOptions = useMemo(
    () =>
      options.map((option) =>
        typeof option === "string" ? { value: option, label: option } : option,
      ),
    [options],
  );
  const selectableOptions = useMemo(
    () => availableOptions.filter((item) => item?.selectable !== false),
    [availableOptions],
  );
  // const notSelectableOptions = useMemo(
  //   () => availableOptions.filter((item) => item?.selectable === false),
  //   [availableOptions],
  // );

  const formattedValue = useMemo(
    () =>
      multiple
        ? availableOptions.filter((option) => value?.includes(option.value))
        : availableOptions.find((option) => option.value === value),
    [multiple, availableOptions, value],
  );
  // const hasUnselectableValue = useMemo(
  //   () =>
  //     multiple
  //       ? notSelectableOptions.filter((option) => value?.includes(option.value))
  //       : notSelectableOptions.find((option) => option.value === value),
  //   [multiple, notSelectableOptions, value],
  // );

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState(formattedValue);
  const onSubmit = async () => {
    setLoading(true);
    document.activeElement?.blur();
    const newValue = multiple
      ? answer.map(({ value }) => value)
      : answer?.value;
    const body = route
      ? { [route]: { [name]: newValue, id: sectionID } }
      : { [name]: newValue };
    try {
      await fetchApi(`update-dossier/${id}`, { body, method: "PUT" });
      fetchData();
    } catch ({ message }) {
      setAnswer(formattedValue);
    }
    setLoading(false);
    setEditing(false);
  };

  const onCancel = () => {
    setEditing(!editing);
    setAnswer(formattedValue);
  };

  const setGroupAnswer = (selectedValue) => {
    const groupSelectedValue = multiple
      ? selectedValue.map((option) => option.value)
      : selectedValue?.value;
    updateGroupAnswers(name, groupSelectedValue, route, sectionID);
  };

  useEffect(() => {
    setAnswer(formattedValue);
  }, [formattedValue]);

  useEffect(() => {
    if (!editAll) {
      setEditing(false);
    }
  }, [editAll]);

  const displayValue = multiple ? (
    formattedValue.length > 0 ? (
      <ul>
        {formattedValue.map(({ value, label }) => (
          <li key={value}>{label}</li>
        ))}
      </ul>
    ) : (
      emptyLabel
    )
  ) : (
    formattedValue?.label || emptyLabel
  );

  return (
    <FieldBlock
      label={label}
      displayValue={displayValue}
      loading={loading}
      editing={editing}
      editAll={editAll}
      // TODO check with @nicolas why readonly change because options={selectableOptions} is supposed solve issue
      readonly={readonly}
      onSubmit={onSubmit}
      onCancel={onCancel}
      setEditing={setEditing}
    >
      <Select
        placeholder={emptyLabel}
        value={answer}
        options={selectableOptions}
        isMulti={multiple}
        className="w-[570px] border-2 border-sky-600 rounded outline-none"
        isLoading={loading}
        isDisabled={loading}
        onChange={(selectedValue) => {
          setAnswer(selectedValue);
          if (editAll) {
            setGroupAnswer(selectedValue);
          }
        }}
      />
    </FieldBlock>
  );
}
FieldBlockSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.number,
  route: PropTypes.string,
  sectionID: PropTypes.number,
  readonly: PropTypes.bool,
  editAll: PropTypes.bool,
  updateGroupAnswers: PropTypes.func,
  fetchData: PropTypes.func,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  emptyLabel: PropTypes.string,
};
