import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";
import AuthForm from "components/Auth/AuthForm";
import AuthMessage from "components/Auth/AuthMessage";
import Input from "components/Input";
import { ReactComponent as SuccessIcon } from "images/icons/success.svg";

export default function CreateAccount() {
  const [fetchApi] = useFetchApi();
  const [searchParams, setSearchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);
  const [defaultValues] = useState({
    title: searchParams.get("title"),
    name: searchParams.get("name"),
    firstname: searchParams.get("firstname"),
    email: searchParams.get("email"),
    telephone: searchParams.get("telephone"),
    simulationId: searchParams.get("simulationId"),
    simulationCode: searchParams.get("simulationCode"),
  });

  useEffect(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const submit = async (data) => {
    await fetchApi(`auth/local/register`, {
      body: {
        ...data,
        username: data?.email,
        simulationId: defaultValues.simulationId,
        simulationCode: defaultValues.simulationCode,
      },
    });
    // Trigger a revalidation (refetch).
    mutate("auth/local");
    setSuccess(true);
  };

  if (success) {
    return (
      <AuthMessage
        title={
          <>
            <SuccessIcon className="mr-2 inline-block align-middle h-5" />
            Votre compte a bien été créé
          </>
        }
        message={
          <>
            Un lien d&apos;activation vient de vous être envoyé par e-mail.
            <br />
            Pour finaliser l&apos;accès à votre compte, consultez votre boite de
            réception.
            <br />
            <br />
            Vous n&apos;avez pas reçu cet e-mail&nbsp;?
            <br />
            Vérifiez vos courriers indésirables.
          </>
        }
      />
    );
  }

  return (
    <AuthForm
      submit={submit}
      title="Créer mon compte"
      submitText="Créer mon compte"
      cancelUrl="/"
      cancelText="J'ai déjà un compte, me connecter."
    >
      <Input
        label="Civilité"
        name="title"
        type="select"
        options={[
          { value: "M.", label: "M." },
          { value: "Mme", label: "Mme" },
        ]}
        defaultValue={defaultValues.title}
        autoComplete="honorific-prefix"
        required
      />
      <Input
        label="Nom"
        name="name"
        type="text"
        defaultValue={defaultValues.name}
        autoComplete="family-name"
        required
      />
      <Input
        label="Prénom"
        name="firstname"
        type="text"
        defaultValue={defaultValues.firstname}
        autoComplete="given-name"
        required
      />
      <Input
        label="E-mail"
        name="email"
        type="email"
        defaultValue={defaultValues.email}
        autoComplete="email"
        required
      />
      <Input
        label="Numéro de téléphone"
        name="telephone"
        placeholder="00 00 00 00 00"
        type="tel"
        defaultValue={defaultValues.telephone}
        autoComplete="tel"
        registerOptions={{
          validate: (value) =>
            !!(value && value.replace(/[\s.]/g, "").match(/^\+?[0-9-]+$/)) ||
            "Merci de renseigner un numéro de téléphone valide.",
        }}
        required
      />
      <Input
        label="Mot de passe"
        name="password"
        type="password"
        autocomplete="new-password"
        placeholder="8 caractères ou plus, dont 1 majuscule et 1 chiffre."
        registerOptions={{
          minLength: {
            value: 8,
            message: "Le mot de passe doit contenir au minimum 8 caractères.",
          },
          validate: (value) =>
            (!!value?.match(/[a-z]/) &&
              !!value?.match(/[A-Z]/) &&
              !!value?.match(/[0-9]/)) ||
            "Le mot de passe doit comporter au moins 8 caractères, 1 majuscule, 1 minuscule et 1 chiffre.",
        }}
        required
      />
      <Input
        label="Confirmer mot de passe"
        name="passwordConfirmation"
        type="password"
        autocomplete="new-password"
        registerOptions={{
          validate: (value, formValues) =>
            value === formValues.password ||
            "Le mot de passe n'est pas identique.",
        }}
        required
      />
    </AuthForm>
  );
}
