import React, { useState } from "react";
import { ReactComponent as DepotEmmyIcon } from "images/icons/depotEmmy.svg";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import { useApiEndpoint } from "ApiEndpointContext";
import Button from "components/Button";
import DossierStatus from "components/DossierStatus";
import DataTable from "components/DataTable";
import { useAuth } from "components/AuthProvider";
import axios from "axios";
import { formatDate, formatAmount } from "functions";

const FILES_PER_PAGE = 12;

const columns = [
  {
    label: "FOST",
    key: "operation",
    selectOptionLabel: "Sélectionner une ou plusieurs FOST",
    filterField: "fosts.numero_fost",
    className: "max-w-[180px]",
    format: (fosts) => (
      <div className="truncate text-black text-ellipsis" title={fosts}>
        {fosts}
      </div>
    ),
  },
  {
    label: "Coup de pouce",
    key: "cdp",
    format: (cdp) => (
      <div className="w-[140px]">
        <input
          type="checkbox"
          className="h-4 w-4 rounded-sm my-auto accent-blue"
          checked={cdp === "Coup de pouce"}
          readOnly
        />
      </div>
    ),
  },
  {
    label: "Statut",
    key: "status",
    selectOptionLabel: "Sélectionner un ou plusieurs statuts",
    filterField: "statut",
    format: (status) => <DossierStatus status={status} />,
  },
  {
    label: "Statut précarité",
    key: "statut_precarite",
    selectOptionLabel: "Sélectionner un ou plusieurs statuts précarité",
    filterField: "statut_precarite",
  },
  {
    label: "Zone géographique",
    key: "zone",
    selectOptionLabel: "Sélectionner un ou plusieurs zones géographiques",
    filterField: "chantiers.zone_climatique",
  },
  {
    label: "Énergie de départ",
    key: "energy",
    selectOptionLabel: "Sélectionner un ou plusieurs énergies",
    filterField: "fosts.energie_de_chauffage",
  },
  {
    label: "Date d'engagement",
    key: "dateSignatureDevis",
    sortFields: ["chantiers.date_signature_devis"],
    defaultDirection: "desc",
    format: formatDate,
  },
  {
    label: "Volume (kWhc)",
    key: "volume",
    sortFields: ["Volume_kWWhc"],
    defaultDirection: "desc",
    format: (volume) => volume ?? "-",
  },
  {
    label: "Prime CEE",
    key: "primeCEE",
    sortFields: ["Prime_CEE"],
    defaultDirection: "desc",
    format: (primeCEE) => formatAmount(primeCEE) ?? "-",
  },
];

function transformApiResponse(res) {
  // We transform the API response to a format that is easier to use and
  // has a lower memory footprint than the full API response.
  return {
    ...res,
    data: res?.data?.map((item) => {
      const cdp =
        item.attributes.fosts.data[0]?.attributes.numero_fost.includes("Coup");
      return {
        id: item.id,
        operation: `${item.attributes.fosts.data[0]?.attributes.numero_fost} ${item.attributes.fosts.data[0]?.attributes.libelle_fost ? item.attributes.fosts.data[0].attributes.libelle_fost : ""}`,
        cdp: cdp ? "Coup de pouce" : "Sans coup de pouce",
        status: item.attributes.statut,
        statut_precarite: item.attributes.statut_precarite ?? "-",
        zone:
          item.attributes.chantiers?.data[0].attributes.zone_climatique ?? "-",
        energy:
          item.attributes.fosts.data[0]?.attributes.energie_de_chauffage ?? "-",
        dateSignatureDevis:
          item.attributes.chantiers?.data[0].attributes.date_signature_devis ??
          "",
        volume: item.attributes.Volume_kWWhc,
        primeCEE: item.attributes.Prime_CEE,
        beneficiaireNom: item.attributes.beneficiaire?.data.attributes.nom,
        beneficiairePrenom:
          item.attributes.beneficiaire?.data.attributes.prenom,
        codePostal: item.attributes.chantiers?.data[0].attributes.code_postal,
        dateCreation: item.attributes.createdAt,
        dateMiseAJour: item.attributes.updatedAt,
        reference: item.attributes.reference,
      };
    }),
  };
}

export default function IndicateurTableau() {
  const [tableQueryParams, setTableQueryParams] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const apiEndpoint = useApiEndpoint();

  const exporterDossiers = async () => {
    try {
      setLoading(true);
      const { pagination, ...exportParams } = tableQueryParams;
      const apiUrl = `${apiEndpoint}/api/export-dossiers`;
      const response = await axios.post(apiUrl, exportParams, {
        headers: {
          Authorization: `Bearer ${auth.token}`,
          "Content-Type": "application/json",
        },
        responseType: "blob",
      });

      // Create a blob from the response data
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "dossiers.xlsx";
      a.click();

      window.URL.revokeObjectURL(url); // Release the object URL
    } catch (error) {
      console.warn(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DataTable
      entity="dossier"
      endpointUrl="dossiers"
      formatData={transformApiResponse}
      columns={columns}
      defaultActiveSort="dateSignatureDevis"
      pageSize={FILES_PER_PAGE}
      flex={false}
      extraParams={{ populate: "*" }}
      setQueryParams={setTableQueryParams}
      footer={
        <Button
          label="Exporter le tableau"
          icon={loading ? <LoadingIcon /> : <DepotEmmyIcon />}
          variant="contained"
          onClick={exporterDossiers}
          disabled={loading}
        />
      }
    />
  );
}
