import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { ReactComponent as ArrowUp } from "images/icons/selection-arrowUp.svg";
import { ReactComponent as ArrowDown } from "images/icons/selection-arrowDown.svg";

export default function Dropdown({ options, value, setValue, label }) {
  const [selectedOption, setSelectedOption] = useState(value || null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOptionSelect = async (newValue) => {
    setSelectedOption(newValue);
    setIsDropdownOpen(false);
    await setValue(newValue);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const filteredOptions = options.filter((option) => !option.disable);
  const { icon, color } = useMemo(() => {
    const { Icon, color } =
      options.find((option) => option.label === selectedOption) || {};
    return { icon: Icon ? <Icon /> : null, color };
  }, [options, selectedOption]);

  const selectedColor = color
    ? `text-white border-white ${color}`
    : `text-main-color border-blue bg-white`;

  return (
    <div
      className={`dropdown cursor-pointer relative ${
        isDropdownOpen ? "open" : ""
      }`}
    >
      <div
        className={`flex items-center rounded-md w-[270px] border-0.5 px-3 py-1 mb-2 ${
          isDropdownOpen ? "border-stone-400" : selectedColor
        }`}
        onClick={toggleDropdown}
      >
        <div
          className={`flex flex-1 items-center ${selectedOption === "Carte d'identité" ? "svg-blue-id" : "svg-blue"}`}
        >
          {icon && <span className={"inline-block mr-3"}>{icon}</span>}
          <span>{selectedOption ?? <>&ndash;</>}</span>
        </div>
        <div>
          {isDropdownOpen && <ArrowUp />}
          {!isDropdownOpen && <ArrowDown />}
        </div>
      </div>
      {isDropdownOpen && (
        <ul className="bg-white rounded-xl absolute z-10 w-[270px] border-0.5 p-3 border-stone-400 flex-1 flex flex-col">
          <h1 className="font-semibold text-main-color mb-3">{label}</h1>
          {filteredOptions.map(({ label, Icon, color }, index) => (
            <li
              key={index}
              onClick={() => handleOptionSelect(label)}
              className={
                selectedOption === label
                  ? `${color ? `text-white ${color}` : "text-main-color"} rounded-md border-0.5 px-3 py-1 mb-2 border-blue border-2 font-semibold`
                  : `${color ? `text-white ${color}` : "text-black-color"} rounded-md border-0.5 px-3 py-1 mb-2 border-stone-400 border-1`
              }
            >
              <span
                className={` flex items-center ${selectedOption !== label ? "" : selectedOption === "Carte d'identité" ? "svg-blue-id" : "svg-blue"}`}
              >
                {Icon && (
                  <span className="inline-block mr-3">
                    <Icon />
                  </span>
                )}
                <span>{label}</span>
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
Dropdown.propTypes = {
  options: PropTypes.arrayOf(Object).isRequired,
  value: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
