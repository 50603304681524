import React from "react";
import PropTypes from "prop-types";

export default function UnimplementedPage({ title }) {
  return (
    <div className="p-8 flex justify-center items-center h-full">
      <div className="border border-blue text-main-color text-xl rounded-2xl border-dashed p-10">
        Page non-implémentée : <span className="font-bold">{title}</span>
      </div>
    </div>
  );
}

UnimplementedPage.propTypes = {
  title: PropTypes.string.isRequired,
};
