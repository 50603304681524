import { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { useAuth } from "components/AuthProvider";
import { useConfig } from "providers/ConfigProvider";

export default function useSocket() {
  const { token } = useAuth();
  const { apiUrl } = useConfig();
  const [isAuthed, setIsAuthed] = useState(false);
  const socket = useRef();

  if (!socket.current) {
    socket.current = io(apiUrl, {
      auth: { token },
    });
    socket.current.on("auth", ({ success }) => setIsAuthed(success));
  }

  useEffect(() => {
    return () => {
      socket.current?.disconnect();
      socket.current = null;
      setIsAuthed(false);
    };
  }, []);

  return [socket.current, isAuthed];
}
