import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import PropTypes from "prop-types";
import { FormContext } from "components/Form";

export default function Input({
  name,
  label,
  register,
  registerOptions,
  placeholder,
  className,
  autoComplete,
  children,
  type,
  value,
  defaultValue,
  onChange,
  required,
  options,
  inputProps,
}) {
  const { loading } = useContext(FormContext) ?? {};
  const {
    register: registerFromContext,
    formState: { errors },
  } = useFormContext();
  const registerFunc = register ?? registerFromContext;
  const error = errors?.[name] ?? null;
  const props = {};
  const registerProps = registerFunc
    ? registerFunc(name, {
        required: required ? "Ce champ est requis" : false,
        ...(registerOptions ?? {}),
      })
    : { required };

  if (inputProps) {
    Object.assign(props, inputProps);
  }

  Object.assign(props, registerProps);

  if (!registerFunc) {
    if (value !== null) {
      Object.assign(props, { value });
    }
    if (onChange) {
      Object.assign(props, { onChange });
    }
  }

  let component = "";
  if (type === "select") {
    component = (
      <select
        id={name}
        name={name}
        className="w-[415px] border border-stone-300 rounded py-5 px-4 text-sm"
        placeholder={label}
        readOnly={loading}
        defaultValue={defaultValue}
        {...props}
      >
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    );
  } else {
    component = (
      <input
        type={type}
        id={name}
        name={name}
        autoComplete={autoComplete}
        className="w-[415px] border border-stone-300 rounded py-5 px-4 text-sm"
        placeholder={placeholder ?? label}
        readOnly={loading}
        defaultValue={defaultValue}
        {...props}
      />
    );
  }

  return (
    <div className={`flex flex-col gap-2 ${className ?? ""}`}>
      {label && (
        <label className="text-main-color" htmlFor={name}>
          {label}
          {required && <sup>*</sup>}
        </label>
      )}
      {component}
      {error?.message && (
        <p className="text-red-500 text-xs">{error.message}</p>
      )}
      {children}
    </div>
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  register: PropTypes.func,
  registerOptions: PropTypes.instanceOf(Object),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  inputProps: PropTypes.instanceOf(Object),
};

Input.defaultProps = {
  label: null,
  register: null,
  registerOptions: null,
  placeholder: null,
  className: "",
  autoComplete: "off",
  children: null,
  type: "text",
  value: null,
  defaultValue: null,
  onChange: null,
  required: false,
  options: [],
  inputProps: null,
};
