import React from "react";
import PropTypes from "prop-types";

function StatusTag({ color, label }) {
  return (
    <div
      className={`inline-block px-2.5 py-1.5 rounded text-white text-center whitespace-nowrap text-xs ${color}`}
    >
      {label}
    </div>
  );
}
StatusTag.propTypes = {
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default StatusTag;
