import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Navigate, Link, useOutletContext } from "react-router-dom";
import useFetchApi from "hooks/useFetchApi";
import useSWR, { mutate } from "swr";
import usePartenairesOptions from "hooks/usePartenairesOptions";
import { ReactComponent as BackIcon } from "images/icons/black-back.svg";
import iconEdit from "images/icons/edit.svg";
import iconCancel from "images/icons/cancel.svg";
import iconConfirm from "images/icons/confirm.svg";
import Roles from "constants/Roles";
import statutsUser from "constants/statutsUser";
import Select from "react-select";

function Block({ label, value }) {
  return (
    <div className="my-3 text-xs">
      <div className="text-sm text-main-color">{label}</div>
      <div className="mt-2 bg-white p-2 py-3 rounded border-0.5 border-stone-400">
        {value || "-"}
      </div>
    </div>
  );
}
Block.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export function BlockSelect({
  label,
  value,
  name,
  options,
  edit,
  handleBody,
  border,
}) {
  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value],
  );

  return (
    <div className="my-3 text-xs">
      <div className="text-sm text-main-color">{label}</div>
      {edit && (
        <Select
          placeholder={options[0].label}
          defaultValue={selectedOption}
          options={options}
          className={`w-full text-sm mt-2 bg-white rounded ${border ?? (edit ? "border border-blue" : "border border-stone-400")}`}
          onChange={(selectedOption) => {
            handleBody({ name, value: selectedOption.value });
          }}
        />
      )}

      {!edit && <div>{selectedOption?.label || <>&ndash;</>}</div>}
    </div>
  );
}
BlockSelect.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  edit: PropTypes.bool.isRequired,
  handleBody: PropTypes.func.isRequired,
  border: PropTypes.string,
};

export function UtilisateursDetails({ userID }) {
  const [fetchApi] = useFetchApi();
  const role = useOutletContext();
  const [edit, setEdit] = useState(false);
  const [body, setBody] = useState();
  const {
    mutate: refreshData,
    data,
    isLoading,
  } = useSWR(`users/${userID}?populate=role,partenaire`, fetchApi);
  const [initSuccess, setInitSuccess] = useState(false);
  const [initError, setInitError] = useState("");

  const { partenairesOptions } = usePartenairesOptions();

  const onCancel = () => {
    setEdit(false);
  };

  const initPassword = async () => {
    const body = { email: data.email };
    try {
      setInitSuccess(false);
      setInitError("");
      await fetchApi("auth/forgot-password", {
        body,
      });
      // Trigger a revalidation (refetch).
      mutate("auth/local");
    } catch ({ message }) {
      setInitError(message);
    } finally {
      setInitSuccess(true);
    }
  };

  const handleBody = ({ name, value }) => {
    switch (name) {
      case "role":
        return setBody((prevBody) => ({
          ...prevBody,
          role: value,
        }));
      case "partenaire":
        return setBody((prevBody) => ({
          ...prevBody,
          partenaire: value ? +value : null,
        }));
      default:
        return setBody((prevBody) => ({
          ...prevBody,
          [name]: value,
        }));
    }
  };

  const updateUserDetails = async () => {
    try {
      setInitSuccess(false);
      setInitError("");
      await fetchApi(`update-user/${userID}`, { method: "PUT", body });
      // Trigger a revalidation (refetch).
      refreshData();
    } catch ({ message }) {
      setInitError(message);
    } finally {
      setEdit(false);
    }
  };

  if (!userID || (!isLoading && !data)) {
    return <Navigate to="/parametres/utilisateurs" />;
  } else {
    return (
      <div className="w-full">
        <div className="p-4 w-[500px] mx-2 mb-4">
          <div className="flex justify-between">
            <Link
              to="/parametres/utilisateurs"
              className="flex font-semibold text-lg flex items-center mb-3"
            >
              <BackIcon className="mr-2" />
              Fiche utilisateur
            </Link>
            <div>
              {edit && (
                <div className="flex space-x-1">
                  <button onClick={updateUserDetails}>
                    <img src={iconConfirm} alt="confirmer" />
                  </button>
                  <button onClick={onCancel}>
                    <img src={iconCancel} alt="annuler" />
                  </button>
                </div>
              )}

              {!edit && role !== "Consultation" && (
                <button onClick={() => setEdit(!edit)}>
                  <img src={iconEdit} alt="modifier" />
                </button>
              )}
            </div>
          </div>

          <Block label="Prénom" value={data?.firstname} />
          <Block label="Nom" value={data?.name} />
          <Block label="E-mail" value={data?.email} />
          <Block label="Numéro de téléphone" value={data?.telephone} />
          <BlockSelect
            label="Rôle"
            value={data?.role?.type}
            name="role"
            options={Roles}
            edit={edit}
            handleBody={handleBody}
          />
          <BlockSelect
            label="Entreprise partenaire"
            value={data?.partenaire?.id}
            name="partenaire"
            options={partenairesOptions}
            edit={edit}
            handleBody={handleBody}
          />
          <BlockSelect
            label="Statut"
            value={data?.blocked}
            name="blocked"
            options={statutsUser}
            edit={edit}
            handleBody={handleBody}
          />
          <button className="text-xs mt-2 underline" onClick={initPassword}>
            Réinitialiser le mot de passe
          </button>
          {initSuccess && (
            <p>
              <span className="text-xs text-green">
                Un e-mail pour réinitialiser le mot de passe a été envoyé.
              </span>
            </p>
          )}
          {initError && (
            <p>
              <span className="text-xs text-red">
                Une erreur s&apos;est produite!
              </span>
            </p>
          )}
        </div>
      </div>
    );
  }
}
UtilisateursDetails.propTypes = {
  userID: PropTypes.number.isRequired,
};
