import React, { useCallback, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useAuth } from "components/AuthProvider";
import DocumentList from "components/MyFileTabs/DocumentList";
import RaiDialog from "components/MyFileTabs/RaiDialog";
import AhDialog from "components/MyFileTabs/AhDialog";
import FileUploader from "components/MyFileTabs/FileUploader";
import MultiFileUploader from "components/MyFileTabs/MultiFileUploader";
import useFetchApi from "hooks/useFetchApi";

function Documents({ id, data, statut }) {
  const [fetchApi] = useFetchApi();
  const [filesData, setFilesData] = useState([]);
  const { role, isManager, isPartner, isCustomer } = useAuth();
  const [raiDialogOpen, setRaiDialogOpen] = useState(false);
  const [ahDialogOpen, setAhDialogOpen] = useState(false);
  const dossierClientId = data.data?.attributes?.client?.data?.id;

  const clientFilesData = useMemo(
    () =>
      filesData.filter(
        (file) => +file.attributes?.proprietaire?.data?.id === +dossierClientId,
      ),
    [dossierClientId, filesData],
  );

  const partnerFilesData = useMemo(
    () =>
      filesData.filter(({ attributes: { channel } }) => channel === "partner"),
    [filesData],
  );

  const managerFilesData = useMemo(() => {
    const excludedIds = [...clientFilesData, ...partnerFilesData].map(
      ({ id }) => id,
    );
    return filesData.filter(({ id }) => !excludedIds.includes(+id));
  }, [clientFilesData, filesData, partnerFilesData]);

  const openModal = (type) => {
    if (type === "RAI") {
      setRaiDialogOpen(true);
    } else if (type === "AH") {
      setAhDialogOpen(true);
    }
  };

  const closeModal = (type) => {
    if (type === "RAI") {
      setRaiDialogOpen(false);
    } else if (type === "AH") {
      setAhDialogOpen(false);
    }
  };

  const fetchFilesData = useCallback(async () => {
    try {
      const apiUrl = `documents?filters[dossier][id][$eq]=${id}&populate=proprietaire`;
      const response = await fetchApi(apiUrl);
      setFilesData(response.data);
    } catch (error) {
      // console.error("Error fetching files data:", error);
    }
  }, [id, fetchApi]);

  useEffect(() => {
    fetchFilesData();
  }, [fetchFilesData]);

  const showAHButton = [
    "RAI envoyé",
    "Travaux en cours",
    "Travaux terminés",
    "Documents reçus et à traiter",
  ].includes(statut);

  const showRAIButton = statut === "Devis validé";

  return (
    <div>
      {raiDialogOpen && (
        <RaiDialog
          onClose={() => {
            closeModal("RAI");
          }}
          isOpen={raiDialogOpen}
          id={id}
          fetchFilesData={fetchFilesData}
          data={data}
        />
      )}

      {ahDialogOpen && (
        <AhDialog
          onClose={() => {
            closeModal("AH");
          }}
          isOpen={ahDialogOpen}
          id={id}
          fetchFilesData={fetchFilesData}
          data={data}
        />
      )}

      {(isManager || isPartner) && (
        <FileUploader
          id={id}
          channel={isPartner ? "partner" : "client"}
          fetchFilesData={fetchFilesData}
        />
      )}
      {isCustomer && (
        <MultiFileUploader
          dossier={data.data}
          files={clientFilesData}
          fetchFilesData={fetchFilesData}
        />
      )}

      {isManager &&
        role !== "Consultation" &&
        (showRAIButton || showAHButton) && (
          <div className="w-full mt-4 flex flex-row-reverse">
            {showRAIButton && (
              <button
                onClick={() => {
                  openModal("RAI");
                }}
                className="inline-flex items-center px-3 py-2 my-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-main-color transition ease-in-out duration-150"
              >
                Générer le RAI
              </button>
            )}
            {showAHButton && (
              <button
                onClick={() => {
                  openModal("AH");
                }}
                className="inline-flex items-center px-3 py-2 my-3 font-semibold leading-6 text-sm shadow rounded-md text-white bg-main-color transition ease-in-out duration-150"
              >
                Générer l&apos;attestation sur l&apos;honneur
              </button>
            )}
          </div>
        )}

      {(isCustomer || isPartner) && (
        <div className="mt-4">
          <DocumentList
            title="Mes documents"
            withState={isCustomer}
            dossier={data.data}
            documents={isCustomer ? clientFilesData : partnerFilesData}
            fetchFilesData={fetchFilesData}
            id={id}
          />
        </div>
      )}

      {isManager && (
        <div className="mt-4">
          <DocumentList
            title="Fichiers attachés par le bénéficiaire"
            dossier={data.data}
            documents={clientFilesData}
            fetchFilesData={fetchFilesData}
            id={id}
          />
        </div>
      )}

      {isManager && (
        <div className="mt-4">
          <DocumentList
            title="Fichiers échangés avec le partenaire"
            withState={false}
            dossier={data.data}
            documents={partnerFilesData}
            fetchFilesData={fetchFilesData}
            id={id}
          />
        </div>
      )}

      {(isCustomer || isManager) && (
        <div className="mt-8">
          <DocumentList
            title="Les documents Sorégies"
            sendButton
            withState={false}
            dossier={data.data}
            documents={managerFilesData}
            fetchFilesData={fetchFilesData}
            id={id}
          />
        </div>
      )}
    </div>
  );
}
Documents.propTypes = {
  id: PropTypes.number.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  statut: PropTypes.string.isRequired,
};

export default Documents;
