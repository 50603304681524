import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "components/Button";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import Dialog from "components/Dialog";
import { translateMessage } from "functions";

export default function ConfirmDialog({
  openDialog,
  setOpenDialog,
  text,
  subText,
  successText,
  action,
  confirmLabel,
  confirmColor,
  cancelLabel,
  cancelColor,
  endLabel,
  endColor,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const doConfirm = async () => {
    if (loading) {
      return;
    }
    try {
      setSuccess(false);
      setError(false);
      setLoading(true);
      await action();
      setSuccess(true);
    } catch (error) {
      console.warn(error);
      setError(translateMessage(error.message));
    } finally {
      setLoading(false);
    }
  };

  const closeDialog = useCallback(() => {
    setOpenDialog(false);
    setError(false);
    setSuccess(false);
    setLoading(false);
  }, [setOpenDialog]);

  useEffect(() => {
    if (openDialog && success && !error && !successText) {
      closeDialog();
    }
  }, [openDialog, success, error, successText, closeDialog]);

  return (
    openDialog && (
      <Dialog onClose={closeDialog}>
        {!success && !error && (
          <div>
            {text && (
              <p className="my-5 text-lg font-semibold text-main-color text-center">
                {text}
              </p>
            )}
            {subText && (
              <p className="my-5 text-sm text-main-color text-center">
                {subText}
              </p>
            )}
            <div className="flex my-2 justify-center">
              <Button
                label={confirmLabel ?? "Oui, je confirme"}
                className="mr-4"
                onClick={doConfirm}
                color={confirmColor ?? "blue"}
                icon={loading ? <LoadingIcon /> : ""}
              ></Button>
              <Button
                label={cancelLabel ?? "Non, j’annule"}
                color={cancelColor ?? "disabled"}
                onClick={closeDialog}
              ></Button>
            </div>
          </div>
        )}
        {(success || error) && (
          <div>
            {success && !error && (
              <p className="my-5 text-xl font-semibold text-main-color text-center">
                {successText}
              </p>
            )}
            {error && (
              <p className="my-5 text-xl font-semibold text-red-500 text-center">
                {error}
              </p>
            )}
            {(error || successText) && (
              <div className="flex my-2 justify-center">
                <Button
                  label={endLabel ?? "Terminé"}
                  className="mr-4"
                  color={endColor ?? "blue"}
                  onClick={closeDialog}
                ></Button>
              </div>
            )}
          </div>
        )}
      </Dialog>
    )
  );
}
ConfirmDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  setOpenDialog: PropTypes.func.isRequired,
  text: PropTypes.any,
  subText: PropTypes.any,
  successText: PropTypes.any,
  action: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  confirmColor: PropTypes.string,
  cancelLabel: PropTypes.string,
  cancelColor: PropTypes.string,
  endLabel: PropTypes.string,
  endColor: PropTypes.string,
};

export function useConfirm() {
  const [openDialog, setOpenDialog] = useState(false);
  const [options, setOptions] = useState();
  const confirm = useCallback((newOptions = {}) => {
    setOptions(newOptions);
    setOpenDialog(true);
  }, []);
  const dialog = options && (
    <ConfirmDialog
      {...options}
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
    />
  );
  return [confirm, dialog];
}
