export default [
  {
    label: "BAR-EN-104 Fenêtre ou porte-fenêtre complète avec vitrage isolant",
    value: "BAR-EN-104",
  },
  {
    label: "BAR-TH-104 Pompe à chaleur de type air/eau ou eau/eau",
    value: "BAR-TH-104",
    selectable: false,
  },
  {
    label:
      "BAR-TH-104 Coup de pouce Pompe à chaleur de type air/eau ou eau/eau",
    value: "BAR-TH-104 Coup de pouce",
    selectable: false,
  },
  {
    label: "BAR-TH-106 Chaudière individuelle à haute performance énergétique",
    value: "BAR-TH-106",
    selectable: false,
  },
  {
    label: "BAR-TH-112 Appareil indépendant de chauffage au bois",
    value: "BAR-TH-112",
  },
  { label: "BAR-TH-113 Chaudière biomasse individuelle", value: "BAR-TH-113" },
  {
    label: "BAR-TH-113 Coup de pouce Chaudière biomasse individuelle",
    value: "BAR-TH-113 Coup de pouce",
  },
  { label: "BAR-TH-129 Pompe à chaleur de type air/air", value: "BAR-TH-129" },
  {
    label:
      "BAR-TH-143 Coup de pouce Système solaire combiné (France métropolitaine)",
    value: "BAR-TH-143 Coup de pouce",
  },
  {
    label: "BAR-TH-148 Chauffe-eau thermodynamique à accumulation",
    value: "BAR-TH-148",
  },
  {
    label: "BAR-TH-159 Coup de pouce Pompe à chaleur hybride individuelle",
    value: "BAR-TH-159 Coup de pouce",
  },
  { label: "BAR-TH-171 Pompe à chaleur de type air/eau", value: "BAR-TH-171" },
  {
    label: "BAR-TH-171 Coup de pouce Pompe à chaleur de type air/eau",
    value: "BAR-TH-171 Coup de pouce",
  },
  { label: "BAR-TH-172 Pompe à chaleur de type eau/eau", value: "BAR-TH-172" },
  {
    label: "BAR-TH-172 Coup de pouce Pompe à chaleur de type eau/eau",
    value: "BAR-TH-172 Coup de pouce",
  },
];
