import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { translateMessage, formatFileSize, formatDate } from "functions";
import { ReactComponent as LoadingIcon } from "images/icons/loading.svg";
import { ReactComponent as CheckIcon } from "images/icons/check.svg";
import Button from "components/Button";
import axios from "axios";
import { useAuth } from "components/AuthProvider";
import { useApiEndpoint } from "ApiEndpointContext";
import {
  RefreshContext,
  useRefresh,
  useEffectRefresh,
} from "providers/RefreshProvider";
import {
  typesDocumentsClient,
  typesDocumentsClientsStatuts,
  typesDocumentsClientToGestionnaire,
} from "constants/typesDocuments";
import { statutsDossierGestionnaireToClient } from "constants/statutsDossier";
import InfoTooltip from "components/InfoTooltip";
import StatusTag from "components/StatusTag";
import { ReactComponent as IconDelete } from "images/icons/delete.svg";
import { NON_CONFORME } from "constants/statutsDocuments";

function validateFileSize(file) {
  if (file.size > 25 * 1024 * 1024) {
    return {
      code: "file-too-large",
      message: `Le fichier fait plus de 25 Mo.`,
    };
  }
  return null;
}

function FileRow({ type, setFilesForType, loading, success }) {
  const { Icon, label, invalid } = type;

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFilesForType(label, acceptedFiles);
    },
    [setFilesForType, label],
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "application/pdf": [".pdf"],
    },
    validator: validateFileSize,
  });

  const handleDelete = useCallback(() => {
    while (acceptedFiles.pop()) {
      // Il faut que ça pop pop pop !
    }
    setFilesForType(label, []);
  }, [acceptedFiles, setFilesForType, label]);

  useEffectRefresh(handleDelete);

  const errors = [];
  fileRejections.forEach((rejectedFile) => {
    rejectedFile?.errors?.forEach((error) => {
      switch (error.code) {
        case "file-invalid-type":
          errors.push("Le fichier doit être au format PDF.");
          break;
        default:
          errors.push(error.message);
          break;
      }
    });
  });

  const hasFiles = acceptedFiles.length > 0;

  return (
    <tr
      {...(!hasFiles && !loading && !success && getRootProps())}
      className={`border border-gray border-x-0 border-t-0 border-b-1 dropzone w-fullcursor-pointer items-center ${
        isDragActive ? "bg-light-color" : ""
      } ${!hasFiles ? "cursor-pointer" : ""} ${success ? "text-green" : "text-main-color"}`}
    >
      <td className={`p-4 w-80 ${loading ? "opacity-50" : ""}`}>
        <input {...getInputProps()} />
        <div
          className={`border bg-white py-1 px-4 text-sm ${success ? "border-green text-green" : "border-blue text-main-color"}`}
        >
          <Icon className="inline-block vertical-align h-4 -mt-1 mr-2" />
          {label}
        </div>
      </td>

      {success && (
        <td colSpan="4" className={`${loading ? "opacity-50" : ""}`}>
          <p className="text-center">
            <CheckIcon className="inline-block vertical-align h-4 -mt-1 -mr-1" />{" "}
            Fichier téléchargé avec succès.
          </p>
        </td>
      )}

      {!hasFiles && !success && (
        <td colSpan="4" className={`${loading ? "opacity-50" : ""}`}>
          <div className="flex flex-row items-center">
            {invalid && (
              <div className={`flex-0 whitespace-nowrap w-32`}>
                <StatusTag
                  label={NON_CONFORME.label}
                  color={NON_CONFORME.color}
                />{" "}
                <InfoTooltip text="Ce fichier est non conforme, veuillez le redéposer." />
              </div>
            )}
            <p className={`flex-1 ${invalid ? "text-right" : "text-center"}`}>
              Glissez votre fichier ou{" "}
              <span className="font-semibold underline">
                parcourez vos fichiers
              </span>
              .
              {errors.length > 0
                ? errors.map((error) => (
                    <>
                      <br />
                      <span
                        key={error}
                        className="font-semibold text-sm text-red-500"
                      >
                        {error}
                      </span>
                    </>
                  ))
                : ""}
            </p>
          </div>
        </td>
      )}

      {!success && hasFiles && (
        <td className={`${loading ? "opacity-50" : ""}`}>
          <ul>
            {acceptedFiles.map((file) => (
              <li key={file.path}>{file.name}</li>
            ))}
          </ul>
        </td>
      )}

      {!success && hasFiles && (
        <td className={`${loading ? "opacity-50" : ""}`}>
          {formatFileSize(acceptedFiles[0].size)}
        </td>
      )}

      {!success && hasFiles && (
        <td className={`${loading ? "opacity-50" : ""}`}>
          {formatDate(acceptedFiles[0].lastModified)}
        </td>
      )}

      {!success && hasFiles && (
        <td className={`w-[25px] text-right`}>
          {!loading && (
            <button onClick={handleDelete}>
              <IconDelete />
            </button>
          )}
          {loading && <LoadingIcon />}
        </td>
      )}
    </tr>
  );
}
FileRow.propTypes = {
  type: PropTypes.instanceOf(Object).isRequired,
  setFilesForType: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool.isRequired,
};

export default function MultiFileUploader({
  dossier,
  files: existingFiles,
  fetchFilesData,
}) {
  const apiEndpoint = useApiEndpoint();
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [files, setFiles] = useState(new Map());
  const [refreshValue, refresh] = useRefresh();

  const statut =
    statutsDossierGestionnaireToClient[dossier?.attributes?.statut];
  const existingTypes = useMemo(
    () =>
      existingFiles
        .filter(
          (file) =>
            file.attributes.dossier_statut === dossier?.attributes?.statut &&
            file.attributes.statut !== "Non conforme",
        )
        .map(({ attributes: file }) => file.type),
    [existingFiles, dossier?.attributes?.statut],
  );
  const invalidTypes = useMemo(
    () =>
      existingFiles
        .filter((file) => file.attributes.statut === "Non conforme")
        .map(({ attributes: file }) => file.type),
    [existingFiles],
  );
  const fileTypeNames = typesDocumentsClientsStatuts[statut];
  const fileTypes = useMemo(
    () =>
      typesDocumentsClient
        .filter(({ label }) => fileTypeNames?.includes(label))
        .filter(({ isVisible }) => !isVisible || isVisible(dossier))
        .map(({ label, ...props }) => ({
          label,
          ...props,
          success: existingTypes.includes(
            typesDocumentsClientToGestionnaire[label],
          ),
          invalid: invalidTypes.includes(
            typesDocumentsClientToGestionnaire[label],
          ),
        })),
    [dossier, existingTypes, invalidTypes, fileTypeNames],
  );
  const filesToUploadCount = useMemo(
    () => fileTypes.filter(({ success }) => !success).length,
    [fileTypes],
  );

  const setFilesForType = useCallback(
    (label, filesForType) => {
      const type = typesDocumentsClientToGestionnaire[label];
      const newFiles = new Map([...files]);
      if (filesForType.length > 0) {
        newFiles.set(type, filesForType);
      } else {
        newFiles.delete(type);
      }
      setFiles(newFiles);
    },
    [files],
  );

  const uploadFiles = useCallback(async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      [...files].forEach(([type, [file]], index) => {
        formData.append(`file${index + 1}`, file);
        formData.append(`type${index + 1}`, type);
      });

      const apiUrl = `${apiEndpoint}/api/dossier/upload-documents/${dossier.id}`;
      await axios.post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${auth.token}`,
        },
      });
      setFiles(new Map());
      refresh();
    } catch (error) {
      setError(translateMessage(error.message));
    } finally {
      await fetchFilesData();
      setLoading(false);
    }
  }, [refresh, apiEndpoint, files, dossier.id, auth.token, fetchFilesData]);

  return fileTypes.length > 0 ? (
    <RefreshContext.Provider value={refreshValue}>
      <div className="w-full">
        <h1 className="text-lg mt-4 mb-3 font-semibold">
          Mes documents à déposer
        </h1>
        <table className="mt-4 border border-x-0 border-t-1 border-b-0 w-full border-gray">
          <tbody>
            {fileTypes.map((type) => (
              <FileRow
                key={type.label}
                loading={loading}
                success={type.success}
                type={type}
                setFilesForType={setFilesForType}
              />
            ))}
          </tbody>
        </table>

        <div className="w-full mt-4 flex flex-row-reverse">
          {filesToUploadCount > 0 ? (
            <Button
              onClick={uploadFiles}
              disabled={loading || files.size === 0}
              label="Je valide mes fichiers"
            />
          ) : null}
          {error && (
            <div className="p-4 font-semibold text-md text-red-500">
              {error}
            </div>
          )}
        </div>
      </div>
    </RefreshContext.Provider>
  ) : null;
}
MultiFileUploader.propTypes = {
  dossier: PropTypes.instanceOf(Object).isRequired,
  files: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  fetchFilesData: PropTypes.func.isRequired,
};
