import React from "react";
import PropTypes from "prop-types";

export default function PageArrow({ disabled, icon, onClick }) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`w-6 h-6 rounded-full flex items-center justify-center ${
        disabled
          ? "bg-stone-300 text-stone-500"
          : "bg-light-color text-main-color"
      }`}
    >
      {icon}
    </button>
  );
}
PageArrow.propTypes = {
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
};
