import React from "react";
import PropTypes from "prop-types";

export default function Input({
  label,
  name,
  register,
  required = false,
  className,
  type = "text",
}) {
  return (
    <div className={`flex flex-col gap-2 ${className ?? ""}`}>
      <label className="text-main-color text-sm" htmlFor={name}>
        {label}
        {required && <>*</>}
      </label>
      <input
        type={type}
        {...register(name)}
        id={name}
        className="w-full border rounded h-10 px-2 text-sm"
        placeholder={label}
        required={required}
      />
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
};
