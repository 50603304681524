import React, { useRef } from "react";
import PropTypes from "prop-types";
import { ReactComponent as SearchIcon } from "images/icons/search.svg";

const SEARCH_TIMEOUT = 300;

export default function Search({ setSearch, placeholder }) {
  const timeout = useRef();

  return (
    <div className="flex items-center max-w-[340px] w-full">
      <input
        type="text"
        id="search"
        className="w-full h-9 border-0.5 border-blue rounded-l-md pl-3 text-sm focus:border-r-0 focus:ring-0"
        placeholder={placeholder}
        onChange={(e) => {
          if (timeout.current) {
            clearTimeout(timeout.current);
          }
          timeout.current = setTimeout(
            () => setSearch(e.target.value),
            SEARCH_TIMEOUT,
          );
        }}
      ></input>
      <label
        htmlFor="search"
        className="h-9 w-12 bg-main-color flex items-center justify-center rounded-r-md text-white"
      >
        <SearchIcon />
      </label>
    </div>
  );
}

Search.propTypes = {
  setSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

Search.defaultProps = {
  placeholder: "Recherche…",
};
