import React from "react";
import { useAuth } from "components/AuthProvider";
import Tarifs from "components/ParametreTabs/Tarifs";
import Utilisateurs from "components/ParametreTabs/Utilisateurs";
import ImportPartenaires from "components/ParametreTabs/ImportPartenaires";
import { UtilisateursDetails } from "components/ParametreTabs/UtilisateursDetails";
import { Link, useParams, useOutletContext, Navigate } from "react-router-dom";
import Main from "components/Main";
import Partenaires from "components/ParametreTabs/Partenaires";

const Parametres = () => {
  const { isAdmin, isManager } = useAuth();
  const { tabID, userID } = useParams();
  const tabs = [
    { id: "tarifs", label: "Tarifs" },
    { id: "utilisateurs", label: "Utilisateurs" },
    ...(isAdmin
      ? [{ id: "import-partenaires", label: "Import partenaires" }]
      : []),
    ...(isManager || isAdmin
      ? [{ id: "partenaires", label: "Partenaires" }]
      : []),
  ];
  const currentID =
    tabID && tabs.find((tab) => tab.id === tabID) ? tabID : "utilisateurs";
  const role = useOutletContext();

  function SwitchTabs(title) {
    switch (title) {
      case "tarifs":
        return <Tarifs />;

      case "utilisateurs":
        return <Utilisateurs />;

      case "utilisateurs-details":
        return <UtilisateursDetails userID={+userID} />;

      case "import-partenaires":
        return isAdmin && <ImportPartenaires />;

      case "partenaires":
        return isAdmin && <Partenaires />;

      default:
        return <Tarifs />;
    }
  }

  if (role !== "Consultation") {
    return (
      <Main title="Paramètres">
        <div className="border-b-2 pb-1 capitalize">
          {tabs.map((item) => (
            <Link to={`/parametres/${item.id}`} key={item.id}>
              <button
                className={
                  currentID === item.id
                    ? "inline mx-4 text-main-color font-medium underline underline-offset-10"
                    : "inline mx-4 font-medium text-[#9E9E9E]"
                }
              >
                {item.label}
              </button>
            </Link>
          ))}
        </div>

        {SwitchTabs(userID ? "utilisateurs-details" : currentID)}
      </Main>
    );
  } else {
    return <Navigate to="/" />;
  }
};

export default Parametres;
