import React, { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import { ReactComponent as CheckIcon } from "images/icons/check.svg";
import useFetchApi from "hooks/useFetchApi";
import { Navigate } from "react-router-dom";

function Input({
  label,
  name,
  register,
  className,
  type = "text",
  required = true,
}) {
  return (
    <div className={`flex flex-col gap-2 ${className ?? ""}`}>
      <label className="text-main-color text-sm" htmlFor={name}>
        {label}
        {required ? "*" : ""}
      </label>
      <input
        type={type}
        {...register(name)}
        id={name}
        className="w-full border rounded h-10 px-2 text-sm"
        placeholder={label}
        required={required}
      />
    </div>
  );
}
Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.boolean,
};

export default function NewFileDialog({ onClose }) {
  const [fetchApi] = useFetchApi();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [resultID, setResultID] = useState(1);
  const onSubmit = async (data) => {
    // We blur the active form so that the user can't submit it twice
    // by pressing the enter key, when showing the overlay.
    document.activeElement?.blur();
    try {
      setLoading(true);
      const result = await fetchApi("create-dossier", {
        body: data,
      });
      // Trigger a revalidation (refetch) of the "Mes dossiers" list.
      setResultID(result.dossier_id ? result.dossier_id : 1);
      setSuccess(true);
      // onClose();
    } catch ({ message }) {
      setError(message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-[80%] max-w-[537px] p-8 grid grid-cols-2 bg-white rounded border relative gap-3"
    >
      <button
        type="button"
        onClick={onClose}
        className="text-xs underline absolute right-4 top-4 z-20"
      >
        Fermer
      </button>
      <div className="col-span-2">
        <h2 className="text-2xl font-semibold text-main-color mb-3">
          Création de dossier
        </h2>
      </div>
      <div className="col-span-2 flex flex-col gap-1">
        <label className="text-sm text-main-color" htmlFor="name">
          Civilité*
        </label>
        <div className="flex gap-6 text-sm text-main-color accent-blue">
          <label htmlFor="madame" className="flex gap-2">
            <input
              {...register("civilite")}
              type="radio"
              value="Madame"
              id="madame"
              required
              className="bg-main-color"
            />
            Madame
          </label>
          <label htmlFor="monsieur" className="flex gap-2">
            <input
              {...register("civilite")}
              type="radio"
              required
              value="Monsieur"
              id="monsieur"
            />
            Monsieur
          </label>
        </div>
      </div>
      <Input label="Prénom" name="prenom" register={register} />
      <Input label="Nom" name="nom" register={register} />
      <Input
        label="Adresse mail"
        name="email"
        type="email"
        register={register}
      />
      <Input label="Téléphone" name="telephone" register={register} />
      <Input
        label="Adresse postale"
        name="adresse"
        className="col-span-2"
        register={register}
      />
      <Input label="Code postal" name="code_postal" register={register} />
      <Input label="Ville" name="ville" register={register} />
      <Input
        label="État / Province / Région"
        name="etat"
        register={register}
        required={false}
      />
      <Input label="Pays" name="pays" register={register} required={false} />
      <div className="col-span-2">
        <Button
          label="Valider le dossier"
          className="mx-auto mt-4"
          variant="contained"
          type="submit"
        />
      </div>
      <span className="absolute left-3.5 bottom-3.5 text-xs text-light-color">
        *champs obligatoire
      </span>
      {(loading || error || success) && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-white rounded flex items-center justify-center p-5">
          {loading && (
            <span className="w-12 h-12 border-4 border-blue border-b-transparent rounded-full animate-spin inline-block box-border"></span>
          )}
          {error && (
            <div className="text-red-500 text-center text-2xl font-semibold">
              Une erreur est survenue lors de la création du dossier !
            </div>
          )}
          {success && (
            <div className="flex text-main-color items-center gap-6">
              <CheckIcon />
              <div className="text-center text-2xl font-semibold">
                Le dossier a bien été créé
              </div>
              <Navigate to={`/dossiers/${resultID}`} />
            </div>
          )}
        </div>
      )}
    </form>
  );
}
NewFileDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};
