import React, { useState } from "react";
import useFetchApi from "hooks/useFetchApi";
import { mutate } from "swr";
import AuthForm from "components/Auth/AuthForm";
import AuthMessage from "components/Auth/AuthMessage";
import Input from "components/Input";
import { ReactComponent as SuccessIcon } from "images/icons/success.svg";

export default function ForgotPassword() {
  const [fetchApi] = useFetchApi();
  const [success, setSuccess] = useState(false);

  const submit = async (data) => {
    await fetchApi("auth/forgot-password", {
      body: data,
    });
    // Trigger a revalidation (refetch).
    mutate("/api/auth/local");
    setSuccess(true);
  };

  if (success) {
    return (
      <AuthMessage
        title={
          <>
            <SuccessIcon className="mr-2 inline-block align-middle h-5" />
            Réinitialisation du mot de passe
          </>
        }
        message={
          <>
            Nous vous avons envoyé un e-mail pour réinitialiser votre mot de
            passe.
            <br />
            Pour créer votre nouveau mot de passe, il vous suffit de cliquer sur
            le lien contenu dans l&apos;e-mail et d&apos;en saisir un nouveau.
            <br />
            <br />
            Vous n&apos;avez pas reçu cet e-mail&nbsp;?
            <br />
            Vérifiez vos courriers indésirables.
          </>
        }
      />
    );
  }

  return (
    <AuthForm
      submit={submit}
      title="Mot de passe oublié"
      submitText="Recevoir le lien"
      cancelUrl="/"
      cancelText="Retour à la page de connexion"
    >
      <p className="text-sm">
        Veuillez saisir votre email de connexion afin de recevoir le lien de
        réinitialisation de votre mot de passe.
      </p>

      <Input
        label="Email de connexion"
        name="email"
        type="email"
        autoComplete="email"
        required
      />
    </AuthForm>
  );
}
